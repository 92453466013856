<template>
  <v-container>
    <v-btn
      v-if="vehicles?.length"
      color="primary"
      elevation="8"
      class="px-10 mb-8 mt-4 add-car-buttons"
      @click="showMobileDialog = true"
    >
      Add Another Car
    </v-btn>
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else-if="vehicles?.length">
      <v-col
        cols="12"
        md="6"
        xl="4"
        v-for="vehicle in vehicles"
        :key="vehicle.id"
      >
        <DisplayCard :vehicle="vehicle" :customer="customer" />
      </v-col>
    </v-row>
    <EmptyData
      v-else
      :time="5"
      eventType="VEHICLE_ACTIVATION"
      header1="Mileage data is valuable"
      header2="With this data, businesses (insurers, mechanics, tire shops, etc.) can personalize offers for your vehicle!"
      imgPath="assets/ownli/car_empty_state.png"
      :benefits="['Mileage Rewards', 'Low Mileage Bonuses']"
    />
    <!-- Add the dialog component -->
    <MobileDialog v-model="showMobileDialog" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/services/api-service";
import EmptyData from "@/components/global/EmptyData.vue";
import DisplayCard from "@/components/vehicle/DisplayCard";
import MobileDialog from "@/components/global/MobileDialog.vue";

export default {
  name: "MyVehicles",
  components: {
    EmptyData,
    DisplayCard,
    MobileDialog,
  },
  computed: {
    ...mapGetters(["customer"]),
  },
  data() {
    return {
      loading: true,
      vehicles: [],
      showMobileDialog: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const url = `customers/${this.customer.id}/vehicles`;
        const response = await api.get(url, {
          requestId: url,
        });
        this.vehicles = response.data;
      } catch (error) {
        console.error("Error fetching vehicles templates:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.add-car-buttons {
  max-width: 400px;
  margin: auto;
}
</style>
