<template>
  <v-card>
    <v-toolbar elevation="0">
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn color="primary" text @click="dismiss"> Close </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-row v-if="!buyerDiscount" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <template v-else>
      <div class="top">
        <video
          v-if="dataBuyer?.videoUrl"
          class="video-wrapper"
          :src="dataBuyer?.videoUrl"
          muted
          controls
          autoplay
        ></video>
        <v-img
          v-else
          class="detail-img"
          contain
          :src="buyerDiscount?.buyer?.logo"
        ></v-img>
        <div v-if="dataBuyer?.shopNowLink" class="topshop">
          <v-btn
            target="_blank"
            :href="dataBuyer?.shopNowLink"
            class="mt-4"
            color="primary"
            >{{ dataBuyer?.shopNowLinkLabel ?? 'Shop Now'}}</v-btn
          >
        </div>
      </div>
      <v-tabs v-model="currentTab" grow>
        <v-tab :ripple="false"> Details </v-tab>
        <v-tab :ripple="false"> Earnings </v-tab>
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item
          class="pa-6 data-buyer-description"
          v-html="dataBuyerDescription"
        >
        </v-tab-item>
        <v-tab-item class="pa-2">
          <!-- <v-simple-table class="borderless-table" v-if="showEarningTable">
            <template v-slot:default>
              <thead>
                <tr>
                  <th
                    style="min-width: 40%"
                    class="primary--text text-h6 font-weight-bold"
                  >
                    Type
                  </th>
                  <th class="primary--text text-h6 font-weight-bold">Price</th>
                  <th class="primary--text text-h6 font-weight-bold">Terms</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(item, index) in earnings">
                  <template v-if="item?.discounts?.length">
                    <tr class="d-block mt-4" :key="'i' + index">
                      <th class="primary--text text-h6 font-weight-bold">
                        {{ item.name }}
                      </th>
                    </tr>
                    <template v-for="(earning, idx) in item.discounts">
                      <tr :key="'e' + idx + index">
                        <td>
                          {{ earning?.rewardTypeDescription }}
                        </td>
                        <td>{{ earning.amountFormatted }}</td>
                        <td>{{ earning?.terms }}</td>
                      </tr>
                      <tr :key="'extra' + idx + index" v-if="earning?.note">
                        <td colspan="3" class="text-break">
                          {{ earning.note }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </template>
          </v-simple-table> -->
          <!-- <div class="mt-4 mx-4"
            v-if="buyerDiscount?.buyer?.earningsDescription"
            v-html="buyerDiscount.buyer.earningsDescription"
          ></div> -->
          <p
            class="mt-4 earnings-description"
            v-if="earningsDescription?.length"
            v-html="earningsDescription"
          />
          <!-- <p class="text-center mt-2 opacity" v-if="showPolicyNote">** Ownli users can add a new active policy when the current one expires.</p> -->
          <v-row v-if="dataBuyer?.shopNowLink">
            <v-col align="center">
              <v-btn
                target="_blank"
                :href="dataBuyer?.shopNowLink"
                class="mt-4"
                color="primary"
                >{{ dataBuyer?.shopNowLinkLabel ?? 'Shop Now'}}</v-btn
              >
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/services/api-service";
import DOMPurify from "dompurify";

export default {
  name: "BuyerModal",
  computed: {
    ...mapGetters(["customer"]),
    showEarningTable() {
      const find = this.earnings.find((e) => e.discounts?.length);
      return !!find;
    },
    showPolicyNote() {
      return this.earnings[2]?.discounts?.length;
    },
  },
  data() {
    return {
      currentTab: "details",
      earnings: [],
      dataBuyer: null,
      buyerDiscount: null,
      earningsDescription: "",
      dataBuyerDescription: "",
    };
  },
  props: ["buyerId", "buyerData"],
  methods: {
    dismiss() {
      this.$emit("dismiss");
    },

    async getDataBuyerAndDiscounts() {
      const url = `data-buyers/${this.$props.buyerId}`;
      const response = await api.get(url, {
        requestId: url,
      });
      if (response?.data) {
        this.dataBuyer = response.data;
        this.earningsDescription = DOMPurify.sanitize(
          this.dataBuyer.earningsDescription || ""
        );
        this.dataBuyerDescription = DOMPurify.sanitize(
          this.dataBuyer.description || ""
        );
        await this.getDiscount();
        //this.populateDiscountsByCondition();
      }
    },

    async getDiscount() {
      const url = `data-buyers/${this.$props.buyerId}/discounts?state=${this.customer.state}&customerId=${this.customer.id}`;
      const response = await api.get(url, {
        requestId: url,
      });
      if (response?.data?.length) {
        this.buyerDiscount = response.data[0];
        this.populateDiscountsByCondition();
      }
    },
    populateDiscountsByCondition() {
      this.earnings = [
        {
          name: "Vehicle",
          discounts: [],
        },
        {
          name: "Property",
          discounts: [],
        },
        {
          name: "Policy",
          discounts: [],
        },
        {
          name: "Survey",
          discounts: [],
        },
        {
          name: "Purchase",
          discounts: [],
        },
      ];
      this.buyerDiscount.discounts.forEach((item) => {
        if (
          (item.type === "Enrollment" || item.type === "Activation") &&
          (item.vertical === "Vehicle Mileage" ||
            item.vertical === "VehicleMileage")
        ) {
          if (!item.rewardTypeDescription) {
            item.rewardTypeDescription = "Mileage";
          }

          if (this.buyerDiscount.campaign.rules[0].throttleValue) {
            item.terms =
              "Monthly/Up to " +
              this.buyerDiscount.campaign.rules[0].throttleValue;
          } else {
            item.terms = "One-time";
          }
          this.earnings[0].discounts.push(item); // Vehicle category
        } else if (
          item.type === "CheckIn" &&
          (item.vertical === "Property External Inspection" ||
            item.vertical === "PropertyExternalInspection")
        ) {
          if (!item.rewardTypeDescription) {
            item.rewardTypeDescription = "Home";
          }
          item.terms = "One-time";
          this.earnings[1].discounts.push(item); // Property category
        } else if (
          item.vertical === "Insurance Policy" ||
          item.vertical === "InsurancePolicy"
        ) {
          if (item.type === "CheckIn") {
            item.rewardTypeDescription = "Manual Add";
          } else {
            item.rewardTypeDescription = "Sync";
          }
          item.terms = "Per policy term**";
          this.earnings[2].discounts.push(item); // Policy category
        } else if (
          item.rewardType !== null &&
          item.rewardType.includes("Survey")
        ) {
          item.terms = "One-time";
          this.earnings[3].discounts.push(item); // Survey category
        } else if (item.rewardType === "Purchase") {
          if (!item.rewardTypeDescription) {
            item.rewardTypeDescription = "Confirmation of Purchase*";
          }
          item.terms = "One-time";
          this.earnings[4].discounts.push(item);
        }
      });
    },
  },
  watch: {
    buyerData: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.buyerDiscount = newValue;
          this.populateDiscountsByCondition();
        }
      },
    },
  },
  mounted() {
    if (this.$props?.buyerId) {
      this.getDataBuyerAndDiscounts();
    }
  },
};
</script>
<style scoped>
.video-wrapper {
  width: 100%;
  max-height: 250px;
}
.borderless-table th,
.borderless-table td,
.borderless-table tr {
  border-bottom: none !important;
}
.borderless-table tbody th,
.borderless-table tbody td,
.borderless-table tbody tr {
  height: auto !important;
}
.fs-18 {
  font-size: 18px;
}
.text-break {
  white-space: break-spaces;
}
.detail-img {
  margin: auto;
  background-size: cover;
  margin: 30px;
  height: 220px;
}
.earnings-description,
.data-buyer-description {
  font-family: "Arial", sans-serif;
  color: #4a4a4a;
  line-height: 1.1;
  
  padding: 25px;
}

.data-buyer-description :deep(img) {
  height: 40px !important;
  width: 40px !important;
  display: block;
  margin-bottom: 10px;
  display: block;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.data-buyer-description :deep(.promo h1),
.data-buyer-description :deep(.promo h2, ),
.data-buyer-description :deep(.promo h3, ),
.earnings-description :deep(.promo h1),
.earnings-description :deep(.promo h2),
.earnings-description :deep(.promo h3),
.earnings-description :deep(.promo h4) {
  font-family: "Arial", sans-serif !important;
  color: #4a4a4a;
  font-size: 20px;
  margin-bottom: 10px;
}

.data-buyer-description :deep(.promo h4, ){
  font-family: "Arial", sans-serif !important;
  color: #4a4a4a;
  font-size: 20px;
  margin-bottom: 10px;
}

.data-buyer-description :deep(.promo h1),
.earnings-description :deep(.promo h1) {
  font-size: 25px;
}
.data-buyer-description :deep(.promo h2),
.earnings-description :deep(.promo h2) {
  font-size: 20px;
}
.data-buyer-description :deep(.promo h3),
.earnings-description :deep(.promo h3) {
  font-size: 20px;
  margin-top: 20px;
}
.data-buyer-description :deep(.promo h4),
.earnings-description :deep(.promo h4) {
  font-size: 15px;
  margin-top: 20px;
}
.data-buyer-description :deep(.promo p),
.earnings-description :deep(.promo p) {
  font-family: "Arial", sans-serif !important;
  color: #4a4a4a;
  text-align: left;
  font-size: 16px;
  margin-bottom: 10px;
}

.earnings-description :deep(p h2),
.data-buyer-description :deep(p h2) {
  margin-top: 10px;
  color: #2c3e50;
}
.earnings-description a {
  color: #1abc9c;
  text-decoration: underline;
}

.mt-4.earnings-description p h4 {
  margin-top: 10px;
  display: block;
}
.earnings-description ul {
  list-style: disc inside;
  margin: 0;
  padding: 0;
}
.topshop {
  position: absolute;
  top: 0px;
  right: 10px;
}
.top {
  position: relative;
}
</style>
