import api from "./api-service";
import store from "@/store";

class RewardsApi {
  static async canClaimRewards() {
    try {
      const response = await api.post("api/rewards/can-claim");
      return response.data;
    } catch (error) {
      console.error("Error checking claim rewards:", error);
      return false;
    }
  }

  static async getSurveySubmitRewards(surveyTemplateId) {
    try {
      const customer = store.getters.customer;
      const response = await api.get(
        `surveys/v1/${customer.id}/${surveyTemplateId}/discounts`
      );
      return response.data || [];
    } catch (error) {
      console.error("Error getting survey submit rewards:", error);
      return [];
    }
  }

  static async getCheckinRewardsFor(checkinId) {
    try {
      const customer = store.getters.customer;
      const response = await api.get(
        `customers/${customer.id}/rewards?checkinId=${checkinId}`
      );
      return response.data || [];
    } catch (error) {
      console.error("Error getting checkin rewards:", error);
      return [];
    }
  }

  static async getVehicleActivationRewards() {
    const customer = store.getters.customer;
    try {
      const response = await api.get(
        `vehicles/${customer.id}/activation/discounts`
      );
      return response.data || [];
    } catch (error) {
      console.error("Error getting vehicle activation rewards:", error);
      return [];
    }
  }

  static async getVehicleCheckinRewards(vehicleId, reading) {
    const customer = store.getters.customer;
    try {
      const response = await api.get(
        `vehicles/${vehicleId}/checkin/discounts?reading=${reading}`
      );
      return response.data || [];
    } catch (error) {
      console.error("Error getting vehicle checkin rewards:", error);
      return [];
    }
  }

  static async getConditionCheckinRewards(vehicleId) {
    try {
      const response = await api.get(
        `vehicles/${vehicleId}/condition-checkin/discounts`
      );
      return response.data || [];
    } catch (error) {
      console.error("Error getting condition checkin rewards:", error);
      return [];
    }
  }

  static async getPropertyCheckinRewards(propertyId, rewardType, verticalId) {
    const customer = store.getters.customer;
    try {
      const endpoint = `properties/${customer.id}/${verticalId}/discounts`;

      const response = await api.get(`${endpoint}?type=${rewardType}`);
      return response.data || [];
    } catch (error) {
      console.error("Error getting property checkin rewards:", error);
      return [];
    }
  }

  static async getInsurancePolicyCheckinRewards(verticalId, userId) {
    try {
      const response = await api.get(
        `policy-checkins/${userId}/${verticalId}/discounts`
      );
      return response.data || [];
    } catch (error) {
      console.error("Error getting insurance policy checkin rewards:", error);
      return [];
    }
  }

  static async getConnectedInsurancePolicyCheckinRewards(verticalId) {
    try {
      const customer = store.getters.customer;
      const response = await api.get(
        `connected-policy-checkins/${customer.id}/${verticalId}/discounts`
      );
      return response.data || [];
    } catch (error) {
      console.error(
        "Error getting connected insurance policy checkin rewards:",
        error
      );
      return [];
    }
  }
}

export default RewardsApi;
