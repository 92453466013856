<template>
  <div id="customer-payouts-container" v-if="payouts && payouts.length">
    <div class="text-center my-3">
      <h3>Payouts</h3>
      <span class="opacity">Payment sent via email from Ownli</span>
    </div>
    <v-card
      v-for="payout in items"
      :key="payout.id"
      class="payout-card"
      @click.stop="toggleReveal(payout)"
    >
      <v-card-text class="pb-0">
        <v-row dense align="center">
          <v-col class="shrink mr-2">
            <!-- <v-avatar size="36px">
                            <PayoutSVG />
                        </v-avatar> -->
            <v-icon>$vuetify.icons.pages_rewards</v-icon>
          </v-col>
          <v-col>
            <h3 v-if="payout.paymentReferenceLink">
              <a :href="payout.paymentReferenceLink" target="_blank"
                >#{{ payout.paymentReferenceId }}</a
              >
            </h3>
            <h3 v-else>#{{ payout.paymentReferenceId }}</h3>
            <span>{{ payout.dateClaimed | moment("DD MMM YYYY") }}</span>
          </v-col>
          <v-col class="shrink">
            <h1>{{ payout.amount | currency(currency, 2) }}</h1>
          </v-col>
        </v-row>
      </v-card-text>

      <v-expand-transition>
        <v-card-text
          v-show="payout.reveal"
          class="transition-fast-in-fast-out v-card--reveal"
        >
          <v-list
            two-line
            color="transparent"
            v-if="payout.rewards && payout.rewards.length > 0"
          >
            <v-list-item
              v-for="reward in payout.rewards"
              :key="reward.id"
              dense
            >
              <v-list-item-avatar
                class="mr-4"
                rounded
                :style="{
                  backgroundColor: '#f5f5f5',
                  padding: '8px',
                  height: '50px',
                  width: '50px',
                }"
              >
                <v-img
                  :src="reward.logo"
                  contain
                  height="100%"
                  width="100%"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="text-body-2"
                  v-text="reward.caption || ''"
                ></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <span class="text-body-2">
                  {{ reward.amount | currency(currency, 2) }}
                </span>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-expand-transition>

      <v-card-actions class="pt-0">
        <v-btn block text small>
          <v-icon>mdi-chevron-{{ payout.reveal ? "up" : "down" }}</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import PayoutSVG from "@/components/timeline/PayoutSVG";
import api from "@/services/api-service";

export default {
  name: "Payouts",

  components: {
    PayoutSVG,
  },

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(["payouts"]),

    policy() {
      return this.customer.policy;
    },
    currency() {
      return this.policy.discountsProviderCurrency;
    },
  },

  data() {
    return {
      loading: false,
      items: this._parsePayouts(),
    };
  },

  methods: {
    toggleReveal(payout) {
      payout.reveal = !payout.reveal;
    },

    _parsePayouts() {
      // console.log("_parseTimeline",this.timelines_dict)
      if (!this.payouts) {
        return [];
      }

      let list = this.payouts.map((i) => {
        return {
          ...i,
          reveal: false,
        };
      });
      return list;
    },

    async load() {
      try {
        this.loading = true;
        // Fetch customer by login e-mail:
        const uri = `/customers/${this.customer.id}/payouts/?Order=DateClaimed desc&Limit=100`;
        //const uri = `/customers/CUST_jbvHfKTkTYkG/payouts/?Order=DateClaimed desc&Limit=100`;
        const response = await api.get(uri, {
          requestId: uri,
        });

        if (response.status == 200 && response.data) {
          this.$store.commit("UPDATE_PAYOUTS", response.data);
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "load",
          },
          error
        );
      } finally {
        this.items = this._parsePayouts();
        this.loading = false;
      }
    },
  },

  mounted() {
    this.items = this._parsePayouts();
    this.load();
  },
};
</script>

<style lang="css" scoped>
.payout-card {
  margin-bottom: 10px;
}

.v-list-item {
  padding: 0;
  min-height: 0;
}

.v-list-item__content,
.v-list-item__action {
  padding: 2px;
  margin: 0;
}
</style>
