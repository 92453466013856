<template>
  <transition name="fade" mode="out-in">
    <v-card
      tile
      flat
      fill-height
      color="transparent"
      class="card-flex card-center"
    >
      <StepOneSVG id="brand-logo-svg" />
      <h3>Login or Signup</h3>
      <v-card-text>
        <v-form
          v-model="valid"
          ref="form"
          @submit.prevent="submit"
          lazy-validation
        >
          <v-container v-if="!sent">
            <v-row dense>
              <v-col cols="12">
                <MobilePhoneInput
                  filled
                  clearable
                  v-model="phone"
                  return-international
                  @country="countryChanged"
                  :enter="send"
                  :country-code="countryCode"
                  :black-listed-country-codes="['JP', 'IL', 'GB']"
                  :allow-country-change="!partner"
                  :hint="`We'll send SMS to ${phone || ' - '}`"
                  label="Mobile Number"
                />
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  x-large
                  color="primary"
                  @click="send"
                  :disabled="loading || !valid || sending"
                  :loading="loading || sending"
                  id="sign-in-up-button"
                >
                  Continue
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container v-else>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  filled
                  clearable
                  v-model="code"
                  :rules="codeRules"
                  required
                  label="SMS Code"
                  type="tel"
                  append-icon="$vuetify.icons.global_cellphone"
                  @keyup.enter.prevent="verify"
                  :loading="loading"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  text
                  @click="sent = false"
                  v-if="!loading"
                  class="text-initial mb-2"
                >
                  Send code again
                </v-btn>
                <v-btn
                  block
                  x-large
                  color="primary"
                  @click="verify"
                  :disabled="loading || !valid || sending"
                  :loading="loading || sending"
                >
                  Verify Code
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <!-- <v-card-text class="z-ind-0 pos-r pa-0">
        <div class="pos-a" id="logo-loader">
          <BrandLogoLoader
            :size="52"
            :gutter="2"
            :infinite="activeHttpRequests || loading"
            color="accent"
          />
        </div>
        <LoginPageSVG
          id="login-svg"
          :color="$vuetify.theme.currentTheme.primary"
        />
        <StepOneSVG id="brand-logo-svg" />
      </v-card-text>
      <v-card-text class="pt-8">
        <h1>Heads Up!</h1>
        <h2 class="my-6">
          We've added new features to help you earn more money, and they're only
          available on our app.
        </h2>
        <h3>
          Continue with your mileage check-ins, add more data for more rewards,
          or get started with Ownli by downloading the app today!
        </h3>
      </v-card-text> -->

      <!-- <v-card-text class="login-btns z-ind-10">
        <v-btn
          x-large
          @click="onAppStoreClick"
          color="accent"
          rounded
          elevation="14"
          :href="appStoreUrl"
          target="_blank"
          id="btn_native_app"
        >
          <div>
            <small>Ownli app on</small>
            <h2>{{ appStoreName }}</h2>
          </div>
          <v-icon large v-html="appStoreIcon"></v-icon>
        </v-btn>
      </v-card-text> -->

      <!-- <v-card-text>
        <h3>
          To learn more about Ownli, head to
          <a href="https://www.ownli.co">www.ownli.co</a>
        </h3>
      </v-card-text>
      <v-spacer /> -->
      <div class="card-divider"><span>OR</span></div>
      <v-card-text class="login-btns z-ind-10">
        <!-- <v-btn
          @click="onPasswordlessClick"
          x-large
          outlined
          color="primary"
          elevation="2"
          to="/passwordless"
          :disabled="activeHttpRequests || loading"
        >
          Continue with SMS
          <v-icon class="ml-3" size="28px">$vuetify.icons.auth_sms</v-icon>
        </v-btn> -->

        <v-btn
          large
          class="mb-2 mt-4"
          outlined
          color="secondary darken-2"
          :disabled="activeHttpRequests || loading"
          @click="loginWithGoogle"
          key="btn-google-login"
        >
          <v-avatar :size="24" class="mr-3">
            <GoogleSVG />
          </v-avatar>
          Continue with Google
        </v-btn>

        <v-btn
          large
          outlined
          color="secondary darken-2"
          :disabled="activeHttpRequests || loading"
          @click="loginWithApple"
          key="btn-apple-login"
        >
          <v-avatar :size="24" class="mr-3">
            <AppleSVG />
          </v-avatar>
          Continue with Apple
        </v-btn>
      </v-card-text>
      <v-spacer />
      <v-spacer />
      <v-card-text class="text-center z-ind-10">
        <div>
          <a
            href="https://support.ownli.co/hc/en-us/articles/360011275958-Terms-of-Use"
            target="_blank"
          >
            Terms of use
          </a>

          <span class="mx-2">&</span>

          <a
            href="https://support.ownli.co/hc/en-us/articles/360011276138-Privacy-policy"
            target="_blank"
          >
            Privacy policy
          </a>

          <!-- <span class="mx-2">|</span>

          <a
            href="https://joinparked.zendesk.com/hc/en-us/requests/new"
            target="_blank"
          >
            Contact Us
          </a> -->
        </div>

        <h5>Powered by OWNLI {{ new Date().getFullYear() }}</h5>
      </v-card-text>
    </v-card>

    <!-- <v-card
      tile
      flat
      dark
      full-width
      fill-height
      class="card-center"
      color="primary"
      v-else
    >
      <v-spacer />
      <v-card-text>
        <BrandLogoLoader ripple circle infinite color="accent" />
        <h2 v-if="user" class="mt-5">{{ user.name || user.email }}</h2>
      </v-card-text>
      <v-spacer />
    </v-card> -->
  </transition>
</template>

<script>
import { mapGetters } from "vuex";

import GoogleSVG from "@/components/global/GoogleSVG";
import AppleSVG from "@/components/global/AppleSVG";
import FacebookSVG from "@/components/global/FacebookSVG";
import EmailLoginSVG from "@/components/global/EmailLoginSVG";
import LoginPageSVG from "@/components/global/LoginPageSVG";
import StepOneSVG from "@/components/onboarding/StepOneSVG";
import MobilePhoneInput from "@/components/global/MobilePhoneInput";
import getMessageByCode from "@/data/firebase-errors.js";

import {
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithPopup,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

export default {
  name: "Login",
  components: {
    GoogleSVG,
    AppleSVG,
    FacebookSVG,
    EmailLoginSVG,
    LoginPageSVG,
    StepOneSVG,
    MobilePhoneInput,
  },

  computed: {
    ...mapGetters([
      "user",
      "partner",
      "customer",
      "device",
      "activeHttpRequests",
      "isPrimaryColorDark",
    ]),

    hasNativeApps() {
      return true;
    },
    showNative_iOS() {
      const isApple = this.device.os.startsWith("iOS");
      return this.hasNativeApps && isApple;
    },
    showNative_Android() {
      const isAndroid = this.device.os.startsWith("Android");
      return this.hasNativeApps && isAndroid;
    },

    appStoreIcon() {
      if (this.showNative_iOS) {
        return "$vuetify.icons.appstore";
      }
      if (this.showNative_Android) {
        return "$vuetify.icons.playstore";
      }
      if (!this.showNative_Android && !this.showNative_iOS) {
        return "$vuetify.icons.web";
      }
      return "$vuetify.icons.actions_download";
    },
    appStoreName() {
      if (this.showNative_iOS) {
        return "App Store";
      }
      if (this.showNative_Android) {
        return "Google Play";
      }
      return "Download App";
    },
    appStoreUrl() {
      if (this.showNative_iOS) {
        return (
          (this.partner && this.partner.nativeAppLink_iOS) ||
          "https://apps.apple.com/us/app/ownli/id1591218440"
        );
      }
      if (this.showNative_Android) {
        return (
          (this.partner && this.partner.nativeAppLink_Android) ||
          "https://play.google.com/store/apps/details?id=com.joinparked.app"
        );
      }
      if (!this.showNative_iOS && !this.showNative_Android) {
        return "https://www.ownli.co";
      }
      return document.location.origin;
    },
  },

  watch: {
    error(v, ov) {
      if (v) {
        this.$store.commit("ADD_NOTIFICATION", {
          text: v,
          timeout: 3000,
        });
      }
    },
  },

  data() {
    return {
      displayLogin: false,
      loading: false,
      error: false,
      valid: false,
      sending: false,
      sent: false,

      country: "United States",
      countryCode: "US",
      dialCode: "1",

      phone: null,
      code: null,
      codeRules: [
        (v) => !!v || "Please enter the SMS code.",
        (v) => (v && v.length == 6) || "Code must be 6 characters long",
      ],
    };
  },

  methods: {
    async _getRedirectResult() {
      try {
        this.loading = true;
        const auth = getAuth();
        let result = await getRedirectResult(auth);
        // console.log("_getRedirectResult:", result);
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "_getRedirectResult",
          },
          error
        );
      } finally {
        this.displayLogin = true;
        this.loading = false;
      }
    },

    async _signInWithProvider(provider) {
      try {
        this.loading = true;
        const auth = getAuth();
        let result = await signInWithPopup(auth, provider);
        // console.log("_signInWithProvider:", result);
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "_signInWithProvider",
          },
          error
        );

        // console.log("_signInWithProvider.error:", error);
      } finally {
        this.loading = false;
      }
    },

    onAppStoreClick() {
      this.$Logger.track(`click_appstore_button`, {
        component: this.$options.name,
        store: this.appStoreName,
      });
    },
    onPasswordlessClick() {
      this.$Logger.track(`click_login_button`, {
        component: this.$options.name,
        method: "sms",
      });
    },
    loginWithGoogle() {
      const provider = new GoogleAuthProvider();
      provider.addScope("openid");
      provider.addScope("email");
      // provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      this._signInWithProvider(provider);
      this.$Logger.track(`click_login_button`, {
        component: this.$options.name,
        method: "google",
      });
    },
    loginWithFacebook() {
      const provider = new FacebookAuthProvider();
      provider.addScope("public_profile");
      provider.addScope("email");
      this._signInWithProvider(provider);
      this.$Logger.track(`click_login_button`, {
        component: this.$options.name,
        method: "facebook",
      });
    },
    loginWithApple() {
      const provider = new OAuthProvider("apple.com");
      provider.addScope("openid");
      provider.addScope("email");
      this._signInWithProvider(provider);
      this.$Logger.track(`click_login_button`, {
        component: this.$options.name,
        method: "apple",
      });
    },
    initializeReCaptcha() {
      const auth = getAuth();
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-up-button",
        { size: "invisible" },
        auth
      );
    },
    resetReCaptcha() {
      window.recaptchaVerifier.render().then(function (widgetId) {
        grecaptcha.reset(widgetId);
      });
    },
    countryChanged(obj) {
      if (obj != null) {
        // console.log("countryChanged", obj);
        this.country = obj.name;
        this.countryCode = obj.code;
        this.dialCode = obj.dial;
      }
    },
    submit() {
      this.$Logger.track(`passwordless_sms`, {
        component: this.$options.name,
      });
    },

    async send() {
      this.$Logger.track(`click_login_button`, {
        component: this.$options.name,
        method: "sms",
      });

      if (!this.$refs.form.validate()) {
        return null;
      }

      try {
        this.sending = true;
        const auth = getAuth();
        const appVerifier = window.recaptchaVerifier;

        let confirmationResult = await signInWithPhoneNumber(
          auth,
          this.phone,
          appVerifier
        );
        window.confirmationResult = confirmationResult;
        // console.log("send.confirmationResult", confirmationResult);
        this.sent = true;
      } catch (error) {
        this.resetReCaptcha();

        this.$Logger.error(
          {
            component: this.$options.name,
            method: "send",
          },
          error
        );

        this.$store.commit("ADD_NOTIFICATION", {
          text:
            getMessageByCode(error.code) ||
            "Oops. something unexpected happened, try again in few moments.",
          timeout: 5000,
        });
      } finally {
        this.sending = false;
      }
    },

    fillPartnerDetails() {
      if (!this.partner) {
        return;
      }
      this.country = this.partner.country;
      this.countryCode = this.partner.countryCode;
      this.dialCode = this.partner.countryDialCode;
      this.onlyCountries = [this.partner.countryCode];
    },

    async verify() {
      if (!this.$refs.form.validate()) {
        return null;
      }

      try {
        this.sending = true;
        let result = await window.confirmationResult.confirm(this.code);
        const user = result.user;
        // console.log("verify.user", user);
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "verify",
          },
          error
        );

        this.$store.commit("ADD_NOTIFICATION", {
          text:
            getMessageByCode(error.code) ||
            "Oops. something unexpected happened, try again in few moments.",
          timeout: 5000,
        });
      } finally {
        this.sending = false;
      }
    },
  },

  mounted() {
    this._getRedirectResult();
    this.fillPartnerDetails();
    this.initializeReCaptcha();
  },
};
</script>

<style scoped>
#login-svg {
  height: auto;
  width: 100%;
}

#logo-loader {
  top: 1rem;
  right: 1rem;
}

.v-card__text > h1 {
  /* font-family: "Merriweather"; */
}

.v-card .card-divider {
  margin: 1.2rem auto;
  width: 100%;
  max-width: 280px;
}

.login-btns .v-btn {
  text-transform: initial !important;
  font-weight: bolder;
  text-align: left;
  max-width: 300px;
  width: 100%;
  height: auto;
  padding: 1rem 1rem;
}

.login-btns > .v-btn__content {
  align-items: center;
  justify-content: space-between;
}
#brand-logo-svg {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  margin-bottom: 1rem;
  max-width: 250px;
}
</style>
