<template>

    <v-progress-circular :rotate="360" :size="size" :width="2" :value="100" color="primary" class="ticker" v-if="timeToCheckIn">
        <v-progress-circular :rotate="360" :size="daysProgressSize" :width="daysProgressSize/2" :value="100" color="secondary" class="counter">
            <v-icon :color="checkMarkColor" class="days-count">$vuetify.icons.global_check</v-icon>
        </v-progress-circular>
    </v-progress-circular>

    <v-progress-circular :rotate="360" :size="size" :width="2" :value="tick" color="accent" class="ticker" v-else>
        <v-progress-circular :rotate="360" :size="daysProgressSize" :width="daysProgressSize/2" :value="vehicle.nextCheckInDaysProgress * 100" color="secondary" class="counter">
            <!-- <span class="days-count">{{ vehicle.nextCheckInDaysCount }}</span> -->
        </v-progress-circular>
    </v-progress-circular>

</template>

<script> 
    import {
        mapGetters
    } from 'vuex';

    export default {
        name: "CheckinTimer",
        inheritAttrs: false,

        props: {
            vehicle: {
                type: Object,
                required: true
            },
            size: {
                type: Number,
                default: 58
            },
        },

        data() {
            return {
                interval: null,
                tick: 0
            }
        },

        computed: {
            ...mapGetters(["isPrimaryColorDark"]),

            checkMarkColor(){
                return this.isPrimaryColorDark ? 'white' : "black";
            },
            daysProgressSize() {
                return this.size - 10;
            },
            timeToCheckIn() {
                return this.vehicle.nextCheckInDaysCount <= 0;
            }
        },

        methods: {
            setTimer() {
                this.interval = setInterval(() => {
                    if (this.tick === 100) {
                        return (this.tick = 0);
                    }
                    this.tick += 5
                }, 1000);
            }
        },

        mounted() {
            if (!this.timeToCheckIn) {
                this.setTimer();
            }
        },

        beforeDestroy() {
            clearInterval(this.interval);
            this.interval = null;
        }
    }
</script>

<style lang="css" scoped>
    .counter>>>.v-progress-circular__underlay {
        stroke: #F5F5F5;
    }

    .days-count {
        color: #212121;
        z-index: 1;
    }
</style>