import api from "./api-service";
import store from "@/store";

class CustomerDataBuyerPreferencesApi {
  static async getPreferences() {
    try {
      const customer = store.getters.customer;
      const response = await api.get(
        `customers/${customer.id}/databuyerpreferences`
      );
      return response.data || { enableAllDataBuyers: false, dataBuyers: [] };
    } catch (error) {
      console.error("Error getting buyer preferences:", error);
      return { enableAllDataBuyers: false, dataBuyers: [] };
    }
  }

  static async saveAllEnabled(enabled) {
    try {
      const customer = store.getters.customer;
      const payload = {
        customerId: customer.id,
        enableAllDataBuyers: enabled,
        dataBuyers: [],
      };
      const response = await api.post("customerdatabuyerpreferences", payload);
      return response.data;
    } catch (error) {
      console.error("Error saving all buyer preferences:", error);
      throw error;
    }
  }

  static async saveBuyerPreference(buyerId, enabled) {
    try {
      const customer = store.getters.customer;
      const payload = {
        customerId: customer.id,
        enableAllDataBuyers: false,
        dataBuyers: [{ dataBuyerId: buyerId, enabled: enabled }],
      };
      const response = await api.post("customerdatabuyerpreferences", payload);
      return response.data;
    } catch (error) {
      console.error("Error saving buyer preference:", error);
      throw error;
    }
  }
}

export default CustomerDataBuyerPreferencesApi;
