<template>
  <v-card class="card-wrapper d-flex flex-column" @click="handleBtnClick">
    <div class="card-img">
      <img
        class="card-v-img"
        :src="survey?.icon ?? rewardImg ?? 'assets/images/survey.png'"
      />
    </div>
    <div class="flex-grow-1 d-flex flex-column justify-space-between">
      <h4 class="ps-4 pt-2 text-capitalize">{{ survey?.title ?? title }}</h4>
      <div v-if="survey" align="center" class="mt-5 pb-5">
        <v-btn
          class="text-capitalize"
          v-if="survey?.haltReason == 'SURVEY_STOPPED'"
          @click="handleBtnClick"
          small
          outlined
          color="error"
        >
          Survey Expired
        </v-btn>
        <v-btn
          class="text-capitalize"
          v-else-if="!survey?.haltReason"
          @click="handleBtnClick"
          small
          outlined
          color="primary"
        >
          {{ isSurveyFilled ? "View Answers" : "Answer now" }}
        </v-btn>
        <v-btn
          class="text-capitalize"
          v-else
          @click="handleBtnClick"
          small
          outlined
          color="primary"
        >
          {{ buttonText }}
        </v-btn>
      </div>
      <div v-else class="mt-5 pb-5">
        <v-btn
          class="text-capitalize ms-4"
          @click="handleBtnClick"
          small
          outlined
          color="primary"
        >
          {{ buttonText }}
        </v-btn>
      </div>
    </div>

    <div class="custom-badge">
      <p>Earn ${{ survey?.reward?.toFixed(2) ?? rewardAmount ?? "" }}</p>
    </div>
  </v-card>
</template>

<script>
import RewardsCarSVG from "@/components/customer/RewardsCarSVG";
export default {
  name: "RewardCard",
  components: {
    RewardsCarSVG,
  },
  props: {
    title: {
      type: String,
    },
    survey: {
      type: Object,
      required: false,
    },
    rewardImg: {
      type: String,
      required: false,
    },
    rewardAmount: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
      default: "Unlock",
    },
    isSurveyFilled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleBtnClick() {
      this.$emit("onClickBtn");
    },
  },
};
</script>
<style lang="scss" scoped>
.card-wrapper {
  min-width: 160px;
  height: 100%;
}
.card-img {
  background: rgba(123, 97, 255, 0.267);
  padding: 8px;
  min-height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  .card-v-img {
    max-width: 140px;
    max-height: 100px;
  }
}
.custom-badge {
  background: var(--v-primary-base);
  color: white;
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 10px !important;
  padding: 5px 12px;
  font-size: 14px;
}
</style>
