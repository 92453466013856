<template>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app id="global-drawer">

        <v-card flat tile>
            <v-card-text v-if="user" class="px-8">
                <v-avatar size="6rem" class="mb-2" v-if="user.photo">
                    <v-img :src="user.photo"></v-img>
                </v-avatar>
                <v-icon size="6rem" color="secondary" class="mb-2" v-else>$vuetify.icons.pages_profile</v-icon>

                <div class="text-left" v-if="customer && customer.nameFull">
                    <h2 class="text-capitalize">{{customer.nameFull}}</h2>
                    <h5>{{customer.contactEmail}}</h5>
                    <!-- <h5>{{customer.contactPhone}}</h5> -->
                </div>
                <div class="text-left" v-else>
                    <h2 class="text-capitalize">{{user.name}}</h2>
                    <h5>{{user.email}}</h5>
                </div>
            </v-card-text>

            <v-divider />

            <v-card-text>
                <v-list dense>
                    <!-- <template v-if="$vuetify.breakpoint.lgAndUp">
                        <v-list-item link to="/vehicles">

                            <v-list-item-action>
                                <v-icon>$vuetify.icons.pages_vehicles</v-icon>
                            </v-list-item-action>

                            <v-list-item-content>
                                My Vehicles
                            </v-list-item-content>

                        </v-list-item>

                        <v-list-item link to="/timeline">

                            <v-list-item-action>
                                <v-icon>$vuetify.icons.pages_timeline</v-icon>
                            </v-list-item-action>

                            <v-list-item-content>
                                My Timeline
                            </v-list-item-content>

                        </v-list-item>

                        <v-list-item link to="/rewards">

                            <v-list-item-action>
                                <v-icon>$vuetify.icons.pages_rewards</v-icon>
                            </v-list-item-action>

                            <v-list-item-content>
                                My Rewards
                            </v-list-item-content>

                        </v-list-item>
                    </template> -->

                    <v-list-item link to="/profile">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_profile</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            My Profile
                        </v-list-item-content>

                    </v-list-item>
                    <!-- <v-list-item link to="/policy">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_policy</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            My Policy
                        </v-list-item-content>

                    </v-list-item> -->

                    <v-list-item link href="https://support.ownli.co/hc/en-us" target="_blank">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_faq</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            FAQ & Support
                        </v-list-item-content>

                    </v-list-item>

                    <v-list-item link href="https://support.ownli.co/hc/en-us/requests/new" target="_blank">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_contact</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            Contact Us
                        </v-list-item-content>

                    </v-list-item>

                    <v-list-item link href="https://www.joinparked.com/about-us" target="_blank">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.pages_about</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            About Us
                        </v-list-item-content>

                    </v-list-item>
                </v-list>
            </v-card-text>

            <v-divider />

            <v-card-text>
                <v-list dense>
                    <!-- <v-list-item link v-if="customer && !partner">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.actions_share</v-icon>
                        </v-list-item-action>


                        <v-list-item-content>
                            <BtnAffiliateShare text :ripple="false" :share-url="customer.affLinkUrl"
                                class="text-initial">
                                <template v-slot:label>Share with Friends</template>
                            </BtnAffiliateShare>
                        </v-list-item-content>

                    </v-list-item> -->

                    <v-list-item @click="signOut">

                        <v-list-item-action>
                            <v-icon>$vuetify.icons.actions_logout</v-icon>
                        </v-list-item-action>

                        <v-list-item-content>
                            Log-Out
                        </v-list-item-content>

                    </v-list-item>

                </v-list>
            </v-card-text>
        </v-card>

        <v-card flat tile class="mb-5">
            <v-card-text>
                <!-- <v-btn x-large block color="accent" elevation="8" :href="appStoreUrl" target="_blank" id="btn_native_app">
                    <v-icon large v-html="appStoreIcon"></v-icon>
                    <div>
                        <small>GET IT ON</small>
                        <h4>{{appStoreName}}</h4>
                    </div>
                </v-btn> -->
                <v-btn class="download-app-btn mb-2" color="black" x-large block href="https://apps.apple.com/us/app/ownli/id1591218440" target="_blank">
                    <v-img src="/assets/images/app-store.svg"></v-img>
                </v-btn>
                <v-btn class="download-app-btn" color="black" x-large block href="https://play.google.com/store/apps/details?id=com.joinparked.app" target="_blank">
                    <v-img src="/assets/images/google-play.svg"></v-img>
                </v-btn>
            </v-card-text>
        </v-card>

        <v-spacer/>

        <v-card flat tile>
            <v-card-actions>
                <v-spacer />

                <v-avatar :size="36">
                    <!-- <v-img src="https://parked.blob.core.windows.net/ownli/www/logos/logo.png" /> -->
                    <v-img :src="`https://parked.blob.core.windows.net/ownli/www/logos/logo.png`" />
                </v-avatar>

                <v-spacer />

                <div class="text-left pr-2" style="font-size:0.72rem;">
                    <span>Powered by Ownli {{ year }}</span><br />
                    <a href="https://support.ownli.co/hc/en-us/articles/360011275958-Terms-of-Use"
                        target="_blank">Terms of use</a> & <a
                        href="https://support.ownli.co/hc/en-us/articles/360011276138-Privacy-policy"
                        target="_blank">Privacy policy</a>
                </div>
                <v-spacer />
            </v-card-actions>
        </v-card>

    </v-navigation-drawer>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import {
        getAuth,
        signOut
    } from "firebase/auth";

    import BtnAffiliateShare from "@/components/customer/BtnAffiliateShare"


    export default {
        name: "NavDrawer",

        components: {
            BtnAffiliateShare,
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                drawer: this.value
            }
        },

        watch: {
            drawer(v, ov) {
                this.$emit("input", v);
            },
            value(v, ov) {
                this.drawer = v;
            }
        },

        computed: {
            ...mapGetters(["user", "device", "customer", "partner"]),

            hasNativeApps() {
                return true;

                if (!this.partner) {
                    return false;
                }
                return this.partner.nativeAppLink_Android != null || this.partner.nativeAppLink_iOS != null;
            },
            showNative_iOS() {
                const isApple = this.device.os.startsWith("iOS");
                return this.hasNativeApps && isApple;
            },
            showNative_Android() {
                const isAndroid = this.device.os.startsWith("Android");
                return this.hasNativeApps && isAndroid;
            },

            appStoreIcon() {
                if (this.showNative_iOS) {
                    return "$vuetify.icons.appstore";
                }
                if (this.showNative_Android) {
                    return "$vuetify.icons.playstore";
                }
                return "$vuetify.icons.actions_download";
            },
            appStoreName() {
                if (this.showNative_iOS) {
                    return "App Store";
                }
                if (this.showNative_Android) {
                    return "Google Play";
                }
                return "download";
            },
            appStoreUrl() {
                if (this.showNative_iOS) {
                    return this.partner && this.partner.nativeAppLink_iOS || "https://apps.apple.com/us/app/ownli/id1591218440";
                }
                if (this.showNative_Android) {
                    return this.partner && this.partner.nativeAppLink_Android || "https://play.google.com/store/apps/details?id=com.joinparked.app";
                }
                return document.location.origin;
            },

            year() {
                return new Date().getFullYear();
            },
        },

        methods: {
            async signOut() {
                try {
                    const auth = getAuth();
                    let result = await signOut(auth);
                    localStorage.removeItem('isCompletedReferral');
                    // console.log("signOut:", result);
                } catch (error) {
                    this.$Logger.error({
                        component: this.$options.name,
                        method: "signOut"
                    }, error);
                    // console.log("signOut.error:", error);
                }
            }
        },

    }
</script>

<style lang="css" scoped>
    #global-drawer>>>.v-navigation-drawer__content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    #btn_share>>>p {
        margin: 0;
    }

    #btn_share>>>p::after {
        content: ' with Friends'
    }

    /* #btn_native_app {
        height: auto;
        padding: 10px;
    } */

    #btn_native_app>>>.v-btn__content {
        align-items: center;
        justify-content: center;
    }

    #btn_native_app>>>.v-btn__content>.v-icon {
        margin-right: 15px;
    }

    #btn_native_app>>>small {
        font-size: 0.6rem;
        font-weight: 300;
    }
    .download-app-btn:hover {
        background-color: #606060 !important;
    }
</style>