// BuyersListModal.vue
<template>
  <div>
    <v-dialog v-model="show" fullscreen hide-overlay>
      <v-card class="modal-container">
        <v-toolbar elevation="0">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="primary"
              text
              class="text-uppercase"
              style="letter-spacing: 1.5px;"
              @click="$emit('close')"
            >
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <buyers-list
          :event-type="eventType"
          :extra-data="extraData"
          @update:loading="setLoading"
          @open-buyer-details="openBuyerDetails"
        />

        <!-- Overlay loader when loading -->
        <div v-if="loading" class="loader-overlay">
          <v-row justify="center" align="center">
            <v-col align="center" cols="12" sm="8" md="6">
              <v-progress-circular
                align="center"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <!-- Buyer Details Modal -->
    <v-dialog v-model="showBuyerDetails" fullscreen hide-overlay>
      <buyer-details
        v-if="selectedBuyerId"
        :buyer-id="selectedBuyerId"
        @dismiss="closeBuyerDetails"
      />
    </v-dialog>
  </div>
</template>

<script>
import BuyersList from "./BuyersList.vue";
import BuyerDetails from "../customer/BuyerDetails.vue";

export default {
  name: "BuyersListModal",
  components: {
    BuyersList,
    BuyerDetails,
  },
  props: {
    value: Boolean,
    eventType: {
      type: String,
      required: true,
      validator: (value) => {
        const isValid = [
          "VEHICLE_ACTIVATION",
          "VEHICLE_CHECKIN",
          "VEHICLE_CONDITION_CHECKIN",
          "PROPERTY_CHECKIN",
          "INSURANCE_POLICY_CHECKIN",
          "CONNECTED_INSURANCE_POLICY_CHECKIN",
          "SURVEY_CHECKIN",
        ].includes(value);

        return isValid;
      },
    },
    extraData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      showBuyerDetails: false,
      selectedBuyerId: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    setLoading(loading) {
      this.loading = loading;
    },
    openBuyerDetails(buyerId) {
      this.selectedBuyerId = buyerId;
      this.showBuyerDetails = true;
    },
    closeBuyerDetails() {
      this.showBuyerDetails = false;
      this.selectedBuyerId = null;
    },
  },
};
</script>

<style scoped>
.modal-container {
  background-color: white;
  margin: 25px 10px;
}
</style>
