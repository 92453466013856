<template>
  <v-card
    class="vehicle-display-card"
    :class="{ inactive: !vehicle.isActiveOnPolicy }"
    :loading="loading"
    v-if="vehicle"
  >
    <v-card-title class="py-1">
      <h4>{{ vehicle.caption }}</h4>
      <v-spacer />
      <v-btn
        icon
        color="accent"
        :to="`/vehicles/${vehicle.id}/`"
        :loading="loading"
      >
        <v-icon>$vuetify.icons.actions_more</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pa-0">
      <CheckinPhotos :checkin="lastCheckIn" v-if="lastCheckIn" />
    </v-card-text>

    <template v-if="vehicle.isActiveOnPolicy">
      <v-card-text v-if="isCurrentDeclined" key="card-text">
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <div class="avatar-container">
              <v-avatar :size="44" color="error" dark>
                <v-icon color="white">$vuetify.icons.global_info</v-icon>
              </v-avatar>
            </div>
          </v-col>
          <v-col>
            <hr class="mb-2 opacity-high" />
            <h3>Check-in declined</h3>
            <p>{{ currentCheckIn.declineReason }}</p>
            <hr class="mt-2 opacity-high" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else-if="lastCheckIn?.status === 'Pending'">
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <div class="avatar-container">
              <v-avatar :size="44" color="error" dark>
                <v-icon color="white">$vuetify.icons.global_info</v-icon>
              </v-avatar>
            </div>
          </v-col>
          <v-col>
            <hr class="mb-2 opacity-high" />
            <h3>Pending approval</h3>
            <p>
              Your check-in is being reviewed. This usually takes upto 1
              business day.
            </p>
            <hr class="mt-2 opacity-high" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else-if="lastCheckIn?.status === 'OnHold'">
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <div class="avatar-container">
              <v-avatar :size="44" color="error" dark>
                <v-icon color="white">$vuetify.icons.global_info</v-icon>
              </v-avatar>
            </div>
          </v-col>
          <v-col>
            <hr class="mb-2 opacity-high" />
            <h3>On Hold</h3>
            <p>
              Your check-in is on hold. We will get in touch with you for any
              additional information we require from you.
            </p>
            <hr class="mt-2 opacity-high" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else-if="!timeToCheckIn" key="card-text">
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <CheckinTimer :vehicle="vehicle" />
          </v-col>
          <v-col>
            <hr class="mb-2 opacity-high" />
            <h3>{{ vehicle.nextCheckInDaysCount }} days until next check-in</h3>
            <p>
              {{ vehicle.nextCheckInDate | moment("MMM DD, YYYY") }}
            </p>
            <hr class="mt-2 opacity-high" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-else key="card-text">
        <v-row align="center" justify="center">
          <v-col cols="auto">
            <CheckinTimer :vehicle="vehicle" />
          </v-col>
          <v-col>
            <hr class="mb-2 opacity-high" />
            <h3>Time to check-in!</h3>
            <p>
              Not by your car now?<br />
              Set a calendar reminder
            </p>
            <hr class="mt-2 opacity-high" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="px-3" key="card-actions">
        <v-btn
          color="primary"
          :disabled="disableCheckIn"
          :loading="loading"
          @click="showMobileDialog = true"
        >
          {{ isCurrentDeclined ? "try again" : "check-in" }}
        </v-btn>
        <ReminderButton
          color="primary"
          :outlined="timeToCheckIn"
          :disabled="offline || loading"
          :loading="loading"
          :title="title"
          :description="description"
          :start="start"
          class="reminder-btn"
        />
      </v-card-actions>
      <v-card-text v-if="hasPendingCheckin" class="text-center py-0 opacity">
        <small
          >Uncommitted Check-In From
          {{ pending_checkin.from | moment("calendar") }}</small
        >
      </v-card-text>
    </template>
    <!-- Add the dialog component -->
    <MobileDialog v-model="showMobileDialog" />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import api from "@/services/api-service";
import moment from "moment";

import ReminderButton from "@/components/global/ReminderButton";
import CheckinTimer from "@/components/vehicle/CheckinTimer";
import CheckinPhotos from "@/components/vehicle/CheckinPhotos";
import MobileDialog from "@/components/global/MobileDialog.vue";
import axios from "axios";

export default {
  name: "DisplayCard",
  components: {
    ReminderButton,
    CheckinTimer,
    CheckinPhotos,
    MobileDialog,
  },
  props: {
    vehicle: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([
      "offline",
      "activeHttpRequests",
      "pending_checkin",
      "checkins_last_dict",
      "token",
    ]),

    policy() {
      return this.customer.policy;
    },
    disableCheckIn() {
      return (
        this.policy.status == "Declined" || !this.timeToCheckIn || this.loading
      );
    },

    hasPendingCheckin() {
      const hasCheckin =
        this.pending_checkin != null &&
        this.pending_checkin.vehicleid == this.vehicleid;
      if (!hasCheckin) {
        return false;
      }
      const hasCheckinData =
        this.pending_checkin.odometerData || this.pending_checkin.vehicleData;
      const pendingCheckInGracePeriodEnds = moment(this.pending_checkin.from)
        .add(1, "days")
        .endOf("day");
      const validPendingCheckIn = pendingCheckInGracePeriodEnds >= moment();
      // console.log("hasPendingCheckin:", pendingCheckInGracePeriodEnds.fromNow(), validPendingCheckIn);
      return hasCheckin && hasCheckinData && validPendingCheckIn;
    },

    currentCheckIn() {
      return (
        (this.checkins_last_dict && this.checkins_last_dict[this.vehicle.id]) ||
        null
      );
    },
    isCurrentDeclined() {
      if (!this.currentCheckIn) return false;
      return this.currentCheckIn.status == "Declined";
    },

    loading() {
      return this.activeHttpRequests;
    },
    timeToCheckIn() {
      return this.vehicle.nextCheckInDaysCount <= 0;
    },
    title() {
      return `Ownli - Check-in your Vehicle ${this.vehicle.make || ""} ${
        this.vehicle.model || ""
      }`;
    },
    description() {
      return "Keep engaged with your policy check-ins reward on Ownli, <a href='https://www.ownli.co/'>Click to Check-in</a>";
    },
    start() {
      let date = this.timeToCheckIn
        ? this.$options.filters.moment(
            new Date(),
            "add",
            "2 hours",
            "YYYY-MM-DD HH:MM"
          )
        : this.vehicle.nextCheckInDate;
      return this.$options.filters.moment(date, "YYYY-MM-DD HH:MM");
    },
  },

  data() {
    return {
      lastCheckIn: this.currentCheckIn,
      showMobileDialog: false,
    };
  },

  methods: {
    async loadLastCheckin() {
      try {
        // Fetch customer by login e-mail:
        const uri = `vehicles/${this.vehicle.id}/last-checkin`;
        const response = await api.get(uri, {
          requestId: uri,
        });

        if (response.status == 200 && response.data) {
          this.lastCheckIn = response.data;
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "loadLastCheckin",
          },
          error
        );
      }
    },
    async loadCheckins() {
      try {
        // Fetch customer by login e-mail:
        const uri = `vehicles/${this.vehicle.id}/checkins`;
        const response = await api.get(uri, {
          requestId: uri,
        });

        if (response.status == 200 && response.data) {
          this.$store.commit("UPDATE_CHECKINS", {
            checkins: response.data,
            vehicleId: this.vehicle.id,
          });
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "loadCheckins",
          },
          error
        );
      }
    },
    async loadCheckInSummary() {
      try {
        const uri = `vehicles/${this.vehicle.id}/checkin-summary`;
        const response = await axios.get(uri, {
          requestId: uri,
          baseURL: "https://ownli-prod-internal-api.azurewebsites.net",
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        console.log("loadCheckInSummary", response);
      } catch (error) {}
    },
  },

  mounted() {
    this.lastCheckIn = this.currentCheckIn;
    // this.loadCheckInSummary();
    this.loadLastCheckin();
    this.loadCheckins();
  },
};
</script>

<style scoped>
.vehicle-display-card.inactive {
  filter: grayscale(1);
}

.vehicle-display-card .v-card__actions > .v-btn {
  width: 49% !important;
}

.vehicle-display-card .v-card__title > h4 {
  line-height: 1.1;
  font-weight: 400;
}

.vehicle-display-card .avatar-container {
  outline: solid 3px;
  border-radius: 50%;
  padding: 3px;
}

.vehicle-display-card .v-card__text h2 {
  font-weight: 400;
  margin-bottom: 5px;
}

.vehicle-display-card .v-card__text p {
  margin: 0;
}
</style>
