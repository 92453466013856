<template>
  <v-card class="vehicle-display-card position-relative">
    <!-- Add the expired badge if policy is expired -->
    <div v-if="policy?.expired" class="expired-badge">
      Expired
    </div>
    <v-card-title class="py-1">
      <v-img max-width="50" src="assets/ownli/policy_done.png" contain></v-img>
      <v-spacer />
      <v-btn
        icon
        color="accent"
        @click="$router.push(`my-policies/${policy?.id}`)"
      >
        <v-icon>$vuetify.icons.actions_more</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text key="card-text" class="p-3 card-text">
      <p>{{ policy?.customer }}</p>
      <div><b>Policy Type: </b>{{ policy?.type }}</div>
      <div>
        <b>Valid Through: </b
        >{{
          policy?.end === "0001-01-01T00:00:00"
            ? ""
            : policy?.end | moment("DD MMM YYYY")
        }}
      </div>
      <div><b>Status: </b>{{ policy?.status }}</div>
    </v-card-text>

    <!-- <v-card-actions class="px-3" key="card-actions">
      <v-btn
        color="primary"
        :disabled="disableCheckIn"
        :loading="loading"
        :to="`/vehicles/${vehicle.id}/check-in`"
      >
        {{ isCurrentDeclined ? "try again" : "check-in" }}
      </v-btn>
      <ReminderButton
        color="primary"
        :outlined="timeToCheckIn"
        :disabled="offline || loading"
        :loading="loading"
        :title="title"
        :description="description"
        :start="start"
      />
    </v-card-actions> -->
    <v-card-text>
      <p class="opacity">You can save up to $996/yr with Insurify.</p>
      <a @click="learnMore">Learn More.</a>
    </v-card-text>
    <v-card-text class="d-flex align-center justify-center gap-4 pt-0">
      <p class="opacity">Have a question?</p>
      <a
        class="text-decoration-none"
        href="https://support.ownli.co/hc/en-us/requests/new"
        target="_blank"
      >
        Contact Us.</a
      >
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DisplayCard",
  props: {
    policy: {
      type: Object,
      required: true,
    },
  },
  emits: ["learnMore"],
  methods: {
    learnMore() {
      this.$emit("learnMore", "DBUY_oXDSereH1TT8");
    },
  },
};
</script>

<style scoped>
.card-text {
  font-size: 18px;
}
.gap-4 {
  gap: 4px;
}
.expired-badge {
  position: absolute;
  top: 40px;
  right: 8px;
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border-radius: 5px!important;  /* This alone might not be enough */
  font-size: 13px;
  border: 1px solid #ef5350;
  z-index: 1;
  /* Add these properties for more consistent rendering */
  display: inline-block;
  line-height: 1.5;
  white-space: nowrap;
  /* Ensure uniform height */
  height: fit-content;
  /* Add these to force uniform border radius */
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  overflow: hidden;
}
.position-relative {
  position: relative;
}
</style>
