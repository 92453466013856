<template>
  <v-app-bar dark color="primary" flat dense>
    <v-btn v-if="backButton" icon exact @click="$router.back()">
      <v-icon large>$vuetify.icons.global_back</v-icon>
    </v-btn>

    <v-toolbar-title class="header-title" v-html="title"></v-toolbar-title>
    <v-spacer></v-spacer>

    <v-btn icon small to="/home-card">
      <v-avatar :size="36" v-if="!activeHttpRequests">
        <BrandLogo />
      </v-avatar>
      <BrandLogoLoader :size="36" :gutter="0" v-else />
    </v-btn>
    <span></span>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NavbarTopWithBackBtn",
  props: {
    headerTitle: {
      type: String,
    },
    backButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(["activeHttpRequests", "connection", "offline"]),
    title() {
      return this.$props.headerTitle || this.$route.meta.title;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-title {
  padding-left: 1.5rem !important;
  font-weight: bold;
  text-transform: capitalize;
}
</style>