<template>
    <div class="check-in-photos" :class="{'declined-check-in':isCurrentDeclined}" :style="`height:${height}px;`">
        <VueCompareImage :leftImage="checkin.licensePlatePhoto" :rightImage="checkin.odometerPhoto" />
        <div class="text-center" v-if="checkin">
            <small>{{ checkin.dateOfReading | moment('MMM DD, YYYY') }}</small>
        </div>
    </div>
</template>

<script>
    import VueCompareImage from 'vue-compare-image';

    export default {
        name: "CheckinPhotos",

        components: {
            VueCompareImage,
        },

        props: {
            checkin: {
                type: Object,
                required: true
            },
        },

        computed: {
            height() {
                // return window.innerHeight / 3;
                return Math.round(((window.innerHeight / 3) + Number.EPSILON) * 100) / 100;
            },

            isCurrentDeclined() {
                if (!this.checkin) return false;
                return this.checkin.status == 'Declined';
            },
        }


    }
</script>

<style scoped>
    .check-in-photos {
        overflow: hidden;
    }

    .check-in-photos.declined-check-in {
        filter: grayscale(1);
    }

    .check-in-photos .vci-container {
        height: 90%
    }
</style>