<template>
    <div class="pa-4">
        <v-card flat full-width fill-height class="card-center" color="transparent">
            <v-card-title>
                <v-avatar tile :size="150">
                    <PolicySVG />
                </v-avatar>
                <h3>My Policy</h3>
            </v-card-title>

            <v-card-text class="pt-0 added-bottom-pad">
                <v-form v-model="valid" ref="form" lazy-validation>
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field filled v-model="form.policyNumber" :disabled="policy.policyNumber != null" label="Policy Number" />
                        </v-col>

                        <v-col cols="6">
                            <v-text-field filled v-model="form.dateStartOfPolicy" :disabled="policy.dateStartOfPolicy != null" type="date"
                                label="Policy start date" />
                        </v-col>
                        <v-col cols="6">
                            <v-text-field filled v-model="form.dateEndOfPolicy" :disabled="policy.dateEndOfPolicy != null" type="date" label="Policy end date" />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field filled v-model="form.insuranceProviderName" label="Insurance Provider"
                                disabled />
                        </v-col>

                        <v-col cols="5">
                            <v-text-field filled v-model="form.state"
                                :label="`${countryCode == 'US' ? 'State':'Country'}`" disabled />
                        </v-col>

                        <v-col cols="7" v-if="form.discountsProviderMaxVehiclesPerPolicy > 1">
                            <v-select filled v-model="form.numberOfVehiclesOnPolicy" item-text="text" item-value="value"
                                :items="maxNumberOfVehiclesOnPolicy" :rules="requiredRule" required disabled
                                label="No. Of Vehicles On Policy">
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <div class="mb-2">
                                Need any help? <a target="_blank"
                                    href="https://support.ownli.co/hc/en-us/requests/new">Contact us</a>
                            </div>
                            <v-btn x-large block color="primary" elevation="8" @click="submit" :loading="loading" v-if="!hideCta">
                                submit
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    import api from "@/services/api-service";
    import PolicySVG from "@/components/customer/PolicySVG";

    export default {
        name: 'Policy',

        components: {
            PolicySVG,
        },

        computed: {
            ...mapGetters(["user", "customer", "partner"]),

            policy() {
                return this.customer && this.customer.policy;
            },

            countryCode() {
                return this.policy.discountsProviderCountryCode;
            },

            maxNumberOfVehiclesOnPolicy() {
                let items = [];
                for (let index = 1; index <= this.policy.discountsProviderMaxVehiclesPerPolicy; index++) {
                    items.push({
                        text: `${index}`,
                        value: index
                    });
                }

                return items;
            },

            hideCta(){
                return this.policy.policyNumber != null &&  this.policy.dateStartOfPolicy != null && this.policy.dateEndOfPolicy != null;
            }
        },

                data() {
            return {
                loading: false,
                loaded: false,
                valid: false,

                requiredRule: [v => !!v || "Field is required"],
                form: Object.assign({}, this.policy),
            }
        },

        methods: {
            async submit() {
                if (!this.$refs.form.validate()) {
                    return null;
                }

                try {
                    this.loading = true;

                    const request = Object.assign({}, this.form);
                    // Put customer's policy data:
                    const response = await api.put(`policies/${this.policy.id}`, request);
                    if (response.status == 202 && response.data) {
                        this.$store.commit("UPDATE_CUSTOMER", response.data);
                        this.$router.replace("/vehicles");
                    }
                } catch (error) {

                    this.$Logger.error({
                        component: this.$options.name,
                        method: "submit"
                    }, error);

                } finally {
                    this.loading = false;
                }
            }
        },

        mounted () {
            this.form = Object.assign({}, this.policy);
        },
    }
</script>

<style scoped>
    .v-input--switch>>>.v-input__slot {
        flex-flow: row-reverse;
    }

    .v-input--switch>>>.v-input__slot .v-input--selection-controls__input {
        margin-right: 0;
        margin-left: 1rem;
    }
</style>