<template>
  <v-container>
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <template v-else-if="policies?.length">
      <div class="text-center mt-4 mb-8">
        <v-btn color="primary" @click="showMobileDialog = true"
          >Add another policy</v-btn
        >
      </div>
      <v-row>
        <v-col
          cols="12"
          md="6"
          xl="4"
          v-for="policy in policies"
          :key="policy.id"
        >
          <DisplayCard :policy="policy" @learnMore="openBuyerModal" />
        </v-col>
      </v-row>
    </template>
    <EmptyData
      v-else
      :time="3"
      eventType="CONNECTED_INSURANCE_POLICY_CHECKIN"
      header1="Save on your insurance"
      header2="With this data, you can discover if you’re overspending on your insurance by working 1:1 with Ownli Insurance expert!"
      imgPath="assets/ownli/insurance_empty_state.png"
      :benefits="[
        'Policy Check-In Rewards.',
        'Optional Ownli Insurance consultation to find ways to save and/or better coverage.',
        'Store your policy info all in one place.',
      ]"
    />
    <!-- learn more modal -->
    <v-dialog v-if="activeBuyerId" v-model="buyerModal" fullscreen hide-overlay>
      <buyer-details
        @dismiss="dismiss"
        :buyerId="activeBuyerId"
      ></buyer-details>
    </v-dialog>
    <!-- Add the dialog component -->
    <MobileDialog v-model="showMobileDialog" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/services/api-service";
import EmptyData from "@/components/global/EmptyData.vue";
import DisplayCard from "@/components/policy/DisplayCard.vue";
import BuyerDetails from "@/components/customer/BuyerDetails.vue";
import { PolicyCheckInTypes } from "../data/constants";
import MobileDialog from "@/components/global/MobileDialog.vue";

export default {
  name: "MyPolicy",
  components: {
    EmptyData,
    DisplayCard,
    BuyerDetails,
    MobileDialog,
  },
  computed: {
    ...mapGetters(["customer", "policies"]),
  },
  data() {
    return {
      loading: true,
      activeBuyerId: null,
      buyerModal: false,
      policyTypeDialog: false,
      policyCheckInTypes: PolicyCheckInTypes,
      showMobileDialog: false,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        //const url = `customers/CUST_jbvHfKTkTYkG/policy-checkins`;
        const url = `customers/${this.customer.id}/policy-checkins`;
        // const cPolicyUrl = `customers/${this.customer.id}/connected-policy-checkins`;
        const response = await api.get(url, {
          requestId: url,
        });
        // const cPolicyResponse = await api.get(cPolicyUrl, {
        //   requestId: cPolicyUrl,
        // });
        // console.log('response buuyer',buyerResp.data);
        var policies = response.data;
        policies = policies || [];
        // Map over the policies and add the expired flag
        const processedPolicies = policies.map((policy) => {
          // Initialize expired as false (default state)
          policy.expired = false;

          // Check if end date exists and is not the default date
          if (policy.end && policy.end !== "0001-01-01T00:00:00") {
            try {
              const now = new Date();
              const endDate = new Date(policy.end);

              // Compare dates
              if (now > endDate) {
                policy.expired = true;
              }
            } catch (e) {
              console.error("Error parsing date:", e);
            }
          }
          return policy;
        });
        this.$store.commit("SET_POLICIES", processedPolicies);
        // this.policies = [...this.policies, ...cPolicyResponse.data];
      } catch (error) {
        console.error("Error fetching vehicles templates:", error);
      } finally {
        this.loading = false;
      }
    },
    openBuyerModal(ev) {
      this.activeBuyerId = ev;
      this.buyerModal = true;
    },
    dismiss() {
      this.buyerModal = false;
      this.activeBuyerId = null;
    },
  },
};
</script>

<style></style>
