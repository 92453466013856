import { render, staticRenderFns } from "./AddToCalendar.vue?vue&type=template&id=673b69e6&scoped=true"
import script from "./AddToCalendar.vue?vue&type=script&lang=js"
export * from "./AddToCalendar.vue?vue&type=script&lang=js"
import style0 from "./AddToCalendar.vue?vue&type=style&index=0&id=673b69e6&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "673b69e6",
  null
  
)

export default component.exports