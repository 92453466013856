<template>
  <v-container>
    <v-btn
      color="primary"
      elevation="8"
      class="px-10 mb-8 mt-4 add-car-buttons"
      @click="$router.replace('/mobile-only')"
    >
      Add Another Car
    </v-btn>
    <v-container>
      <v-row v-if="loading" justify="center" align="center">
        <v-col align="center" cols="12" sm="8" md="6">
          <v-progress-circular
            align="center"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else-if="vehicles?.length">
        <v-col
          cols="12"
          md="6"
          xl="4"
          v-for="vehicle in vehicles"
          :key="vehicle.id"
        >
          <DisplayCard :vehicle="vehicle" :customer="customer" />
        </v-col>
      </v-row>
      <EmptyData
        v-else
        :time="5"
        header1="Mileage data is valuable"
        header2="With this data, businesses (insurers, mechanics, tire shops, etc.) can personalize offers for your vehicle!"
        imgPath="assets/ownli/car_empty_state.png"
        :benefits="['Mileage Rewards', 'Low Mileage Bonuses']"
      />
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import api from "@/services/api-service";

export default {
  name: "Vehicles",

  components: {
    ActivationCard: () => import("@/components/vehicle/ActivationCard"),
    DisplayCard: () => import("@/components/vehicle/DisplayCard"),
  },

  computed: {
    ...mapGetters(["customer", "vehicles", "activeHttpRequests"]),

    activeVehiclesCount() {
      let active = this.vehicles.filter((v) => v.isActiveOnPolicy);
      return active.length;
    },
    requireActivation() {
      return (
        this.activeVehiclesCount < this.customer.policy.numberOfVehiclesOnPolicy
      );
    },
  },

  methods: {
    async loadVehicles() {
      try {
        // Fetch customer by login e-mail:
        const uri = `customers/${this.customer.id}/vehicles`;
        const response = await api.get(uri, {
          requestId: uri,
        });

        this.$store.commit("UPDATE_VEHICLES", response.data);
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "loadVehicles",
          },
          error
        );
      }
    },
  },

  mounted() {
    this.loadVehicles();
  },
};
</script>
<style scoped>
.add-car-buttons {
  max-width: 400px;
  margin: auto;
}
</style>
