<!-- MobileDialog.vue -->
<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
    @click:outside="close"
  >
    <v-card class="py-5" rounded>
      <v-card-title class="d-flex justify-end">
        <v-btn
          icon
          @click="close"
        >
          <v-icon>$vuetify.icons.close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <h3 style="font-weight: 400" class="text-center">
          This is an app-only feature.  Download the Ownli app now to continue.
        </h3>
      </v-card-text>

      <v-spacer />

      <div class="d-flex flex-wrap align-center justify-center gap-2 mb-4">
        <v-btn
          class="download-app-btn"
          color="black"
          href="https://apps.apple.com/us/app/ownli/id1591218440"
          target="_blank"
          x-large
        >
          <v-img src="/assets/images/app-store.svg"></v-img>
        </v-btn>
        <v-btn
          class="download-app-btn"
          color="black"
          href="https://play.google.com/store/apps/details?id=com.joinparked.app"
          target="_blank"
          x-large
        >
          <v-img src="/assets/images/google-play.svg"></v-img>
        </v-btn>
      </div>

      <v-img
        class="bottom-card-img"
        src="/assets/images/mobile-only.svg"
        contain
      ></v-img>

      <div class="mt-5 text-center">
        <v-btn
          class="text-capitalize"
          small
          color="primary"
          @click="close"
        >
          Close
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MobileDialog",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    close() {
      this.$emit('input', false);
    }
  }
};
</script>

<style scoped>
.bottom-card-img {
  max-height: 150px;
}
.gap-2 {
  gap: 12px;
}
.download-app-btn:hover {
  background-color: #606060 !important;
}
</style>