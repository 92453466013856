<template>
  <v-container>
    <p class="text-h6">
      We found businesses that will pay YOU directly for your opinion!
    </p>
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row class="flex-grow-0" v-else>
      <v-col v-for="(survey, index) in surveys" :key="index">
        <RewardCard
          :isSurveyFilled="isSurveyFilled(survey.id)"
          @onClickBtn="navigate(survey)"
          :survey="survey"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="surveyExpiredDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h6 error--text">
          Survey Expired
        </v-card-title>

        <v-card-text>
          Thank you for your interest. Unfortunately, the survey has reached its
          time limit and is currently closed. Stay tuned for the next one!
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click="surveyExpiredDialog = false">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="checkInDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h6 error--text">
          Vehicle Check-in required.
        </v-card-title>

        <v-card-text>
          To unlock the Survey, you need to have a confirmed Vehicle check-in.
        </v-card-text>
        <v-row>
          <v-col align="center">
            <v-btn color="primary" @click="checkInDialog = false;showMobileDialog = true"> Add Vehicle </v-btn>
          </v-col>
        </v-row>

        <v-card-actions class="mt-4">
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            text
            @click="
              checkInDialog = false
            "
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Add the dialog component -->
    <MobileDialog v-model="showMobileDialog" />
  </v-container>
</template>
<script>
import RewardCard from "@/components/customer/RewardCard";
import MobileDialog from "@/components/global/MobileDialog.vue";
import { mapGetters } from "vuex";

import api from "@/services/api-service";
export default {
  name: "Surveys",
  components: {
    RewardCard,
    MobileDialog,
  },
  computed: {
    ...mapGetters(["customer"]),
    isSurveyFilled() {
      return (id) => {
        return this.customerSurveyTemplateIds.includes(id);
      };
    },
  },
  data() {
    return {
      loading: true,
      surveys: [],
      customerSurveyTemplateIds: [],
      customerSurveyTemplates: [],
      surveyExpiredDialog: false,
      checkInDialog: false,
      showMobileDialog: false,
    };
  },
  methods: {
    navigate(survey) {
      if (survey?.haltReason == "SURVEY_STOPPED") {
        this.surveyExpiredDialog = true;
      } else if (!survey?.haltReason) {
        if (this.isSurveyFilled(survey.id)) {
          const customerSurveyTemplate = this.customerSurveyTemplates.find(
            (e) => e.surveyTemplateId === survey.id
          );
          this.$router.push({
            name: "SurveyDetails",
            params: {
              id: survey.id,
            },
            query: {
              isSurveyFilled: customerSurveyTemplate.id,
            },
          });
        } else {
          this.$router.push(`surveys/${survey.id}`);
        }
      } else {
        this.checkInDialog = true;
      }
    },
    async getCustomerTemplate() {
      try {
        const url = `customers/${this.customer.id}/survey-templates?apiVersion=2`;
        const response = await api.get(url, {
          requestId: url,
        });
        this.surveys = response.data ?? [];
        const result = await this.getCustomerSurvey();
        if (result?.data?.length) {
          this.customerSurveyTemplates = result.data;
          this.customerSurveyTemplateIds = result.data.map(
            (r) => r.surveyTemplateId
          );
        }
      } catch (error) {
        console.error("Error fetching survey templates:", error);
      } finally {
        this.loading = false;
      }
    },
    async getCustomerSurvey() {
      const url = `customers/${this.customer.id}/surveys`;
      const response = await api.get(url, {
        requestId: url,
      });
      return response;
    },
  },
  mounted() {
    this.getCustomerTemplate();
  },
};
</script>
