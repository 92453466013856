<template>

    <v-bottom-sheet v-model="sheet" inset app v-if="sheet">
        <v-card tile class="card-center" dark id="add-to-calendar">
            <v-card-actions class="py-5">
                <v-btn color="accent" text :href="this.links.google" target="_blank" @click="onCalendarLinkClick('google')">
                    google
                    <v-icon right>$vuetify.icons.calendar_google</v-icon>
                </v-btn>
                <v-btn color="accent" text :href="this.links.office365" target="_blank" @click="onCalendarLinkClick('office365')">
                    office 365
                    <v-icon right>$vuetify.icons.calendar_microsoft_office</v-icon>
                </v-btn>

                <v-btn color="accent" text :href="this.links.outlook" target="_blank" @click="onCalendarLinkClick('outlook')">
                    outlook
                    <v-icon right>$vuetify.icons.calendar_microsoft_outlook</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-bottom-sheet>

</template>

<script>
    import {
        mapGetters
    } from 'vuex';

    import {
        google,
        outlook,
        office365,
        yahoo,
        ics
    } from "calendar-link";

    export default {
        name: "AddToCalendar",
        inheritAttrs: false,

        props: {
            value: {
                type: Boolean,
                default: false
            },
            title: {
                type: String,
                default: 'MMMM...'
            },
            description: {
                type: String,
                default: 'What should i remember?'
            },
            start: {
                type: String,
                default: new Date().toISOString()
            },
            duration: {
                type: Number,
                default: 5
            },
        },

        data: () => ({
            sheet: false,
            links: {
                ics: null,
                google: null,
                yahoo: null,
                outlook: null,
                office365: null,
            }
        }),

        computed: {
            ...mapGetters(["isPrimaryColorDark"]),

            reminderColor(){
                return this.isPrimaryColorDark ? "accent" : "primary";
            },
        },

        watch: {
            value(v) {
                if (v) {
                    this.creatCalendarLink();
                }
                this.sheet = v;
            },
            sheet(v) {
                this.$emit("input", v);
            }
        },

        methods: {
            onCalendarLinkClick(provider) {
                this.$Logger.track(`reminder_set`, {
                    component: this.$options.name,
                    type: provider
                });
            },

            creatCalendarLink() {
                // Set event as an object
                const event = {
                    title: this.title,
                    description: this.description,
                    start: this.start,
                    duration: [this.duration, "minute"]
                };

                // Then fetch the link
                this.links.google = google(event); // https://calendar.google.com/calendar/render...
                this.links.outlook = outlook(event); // https://outlook.live.com/owa/...
                this.links.office365 = office365(event); // https://outlook.office.com/owa/...
                this.links.yahoo = yahoo(event); // https://calendar.yahoo.com/?v=60&title=...
                this.links.ics = ics(event); // standard ICS file based on https://icalendar.org
            }
        },

        created() {
            this.sheet = this.render;
        }
    }
</script>

<style lang="css" scoped>
    /* #add-to-calendar>>>.v-btn__content {
        justify-content: space-between;
        color: var(--v-primary-base);
    } */
</style>