<template>
  <div class="pa-4">
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img :src="user.photo" v-if="user && user.photo" />
            <BrandLogo v-else />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="text-h6"
              v-text="customer.nameFirst + ' ' + customer.nameLast"
            ></v-list-item-title>
            <v-list-item-subtitle>{{ memberSince }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-row class="mt-4" style="flex: none;">
      <!-- Contact Information  -->
      <v-col cols="12" md="6">
        <v-card>
          <v-list dense>
            <v-list-group
              append-icon="mdi-menu-down"
              prepend-icon="mdi-email"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize"
                    >Contact information</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item class="pl-4">
                <v-list-item-content>
                  <v-row class="mt-3 mb-1">
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="customer.contactPhone"
                        disabled
                        label="Mobile number"
                        type="tel"
                        hide-details
                      >
                      </v-text-field>
                      <v-row>
                        <v-col
                          v-if="!customer.isPhoneVerified"
                          class="pt-5 text-capitalize d-flex align-center justify-end gap-2"
                        >
                          <a
                            class="text--secondary text-decoration-underline"
                            @click="sendCode(1)"
                            >verify now</a
                          >
                          <v-icon color="error">mdi-information</v-icon>
                        </v-col>
                        <v-col
                          v-else
                          class="pt-5 text-capitalize d-flex align-center justify-end gap-2"
                        >
                          <span class="text--secondary">verified</span>
                          <v-icon color="success"
                            >mdi-checkbox-marked-circle</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        v-model="customer.contactEmail"
                        :rules="emailRules"
                        required
                        label="Primary e-mail used for notifications"
                        type="email"
                        :append-icon="
                          emailFocus ? 'mdi-content-save' : 'mdi-pencil'
                        "
                        @focus="emailFocus = true"
                        @blur="emailFocus = false"
                        @click:append="updateContactEmail"
                        :loading="ContactEmailLoading"
                        hide-details
                      />
                      <v-row>
                        <v-col
                          v-if="!customer.isEmailVerified"
                          class="pt-5 text-capitalize d-flex align-center justify-end gap-2"
                        >
                          <a
                            class="text--secondary text-decoration-underline"
                            @click="sendCode(0)"
                            >verify now</a
                          >
                          <v-icon color="error">mdi-information</v-icon>
                        </v-col>
                        <v-col
                          v-else
                          class="pt-5 text-capitalize d-flex align-center justify-end gap-2"
                        >
                          <span class="text--secondary">verified</span>
                          <v-icon color="success"
                            >mdi-checkbox-marked-circle</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>

      <!-- About You  -->
      <v-col cols="12" md="6">
        <v-card>
          <v-list dense>
            <v-list-group
              append-icon="mdi-menu-down"
              prepend-icon="mdi-account"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize"
                    >About you</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item class="pl-4">
                <v-list-item-content>
                  <v-row class="mt-3">
                    <v-col cols="12" md="6">
                      <v-select
                        label="Gender*"
                        outlined
                        hide-details
                        :items="lookups?.Gender"
                        item-text="description"
                        item-value="lookupKey"
                        @change="updateField('Gender', $event)"
                        :value="customer.gender"
                        :loading="GenderLoading"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        label="Ethnicity*"
                        outlined
                        hide-details
                        :items="lookups?.Ethnicity"
                        item-text="description"
                        item-value="lookupKey"
                        :value="customer.ethnicity"
                        :loading="EthnicityLoading"
                        @change="updateField('Ethnicity', $event)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        label="Age Range*"
                        outlined
                        hide-details
                        :items="lookups?.AgeRange"
                        item-text="description"
                        item-value="lookupKey"
                        :value="customer.ageRange"
                        :loading="AgeRangeLoading"
                        @change="updateField('AgeRange', $event)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        label="State"
                        outlined
                        hide-details
                        :items="lookups?.State"
                        item-text="description"
                        item-value="lookupKey"
                        :value="customer.state"
                        :loading="StateLoading"
                        @change="updateField('State', $event)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :append-icon="
                          zipcodeFocus ? 'mdi-content-save' : 'mdi-pencil'
                        "
                        label="Zipcode"
                        type="number"
                        outlined
                        :rules="zipcodeRules"
                        @focus="zipcodeFocus = true"
                        @blur="zipcodeFocus = false"
                        @click:append="updateZipcode"
                        v-model="customer.zipcode"
                        :loading="ZipcodeLoading"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>

      <!-- Occupation  -->
      <v-col cols="12" md="6">
        <v-card>
          <v-list dense>
            <v-list-group
              append-icon="mdi-menu-down"
              prepend-icon="mdi-card-account-details"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize"
                    >Occupation</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item class="pl-4">
                <v-list-item-content class="d-block">
                  <v-row class="mt-3 mb-1">
                    <v-col cols="12" md="6">
                      <v-select
                        label="Education*"
                        outlined
                        hide-details
                        :items="lookups?.EducationLevel"
                        item-text="description"
                        item-value="lookupKey"
                        :value="customer.educationLevel"
                        :loading="EducationLevelLoading"
                        @change="updateField('EducationLevel', $event)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        label="Employment Status*"
                        outlined
                        hide-details
                        :items="lookups?.EmploymentStatus"
                        item-text="description"
                        item-value="lookupKey"
                        :value="customer.employmentStatus"
                        :loading="EmploymentStatusLoading"
                        @change="updateField('EmploymentStatus', $event)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        label="Income*"
                        outlined
                        hide-details
                        :items="lookups?.Income"
                        item-text="description"
                        item-value="lookupKey"
                        :value="customer.income"
                        :loading="IncomeLoading"
                        @change="updateField('Income', $event)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>

      <!-- Relationships  -->
      <v-col cols="12" md="6">
        <v-card>
          <v-list dense>
            <v-list-group
              append-icon="mdi-menu-down"
              prepend-icon="mdi-account-multiple"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize"
                    >Relationships</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item class="pl-4">
                <v-list-item-content class="d-block">
                  <v-row class="mt-3">
                    <v-col cols="12" md="6">
                      <v-select
                        label="Relationship Status*"
                        outlined
                        hide-details
                        :items="lookups?.RelationshipStatus"
                        item-text="description"
                        item-value="lookupKey"
                        :value="customer.relationshipStatus"
                        :loading="RelationshipStatusLoading"
                        @change="updateField('RelationshipStatus', $event)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        :append-icon="
                          numberOfChild ? 'mdi-content-save' : 'mdi-pencil'
                        "
                        label="Number of Children"
                        type="number"
                        outlined
                        @focus="numberOfChild = true"
                        @blur="numberOfChild = false"
                        :rules="numberChildrenRules"
                        :loading="NumberChildrenLoading"
                        @click:append="updateNumberChildren"
                        v-model="customer.numberChildren"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>

      <!-- Your residence  -->
      <v-col cols="12" md="6">
        <v-card>
          <v-list dense>
            <v-list-group
              append-icon="mdi-menu-down"
              prepend-icon="mdi-home"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize"
                    >Your residence</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item class="pl-4">
                <v-list-item-content>
                  <v-row class="mt-3 mb-1">
                    <v-col cols="12" md="6">
                      <v-select
                        label="Living Conditions*"
                        outlined
                        hide-details
                        :items="lookups?.HomeOwnership"
                        item-text="description"
                        item-value="lookupKey"
                        :value="customer.homeOwnership"
                        :loading="HomeOwnershipLoading"
                        @change="updateField('HomeOwnership', $event)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        label="Home Type*"
                        outlined
                        hide-details
                        :items="lookups?.HomeType"
                        item-text="description"
                        item-value="lookupKey"
                        :value="customer.homeType"
                        :loading="HomeTypeLoading"
                        @change="updateField('HomeType', $event)"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>

      <!-- Settings  -->
      <v-col cols="12" md="6">
        <v-card>
          <v-list dense>
            <v-list-group
              append-icon="mdi-menu-down"
              prepend-icon="mdi-cog"
              no-action
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize"
                    >Settings</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-item class="pl-4">
                <v-list-item-content style="overflow: initial;">
                  <v-row>
                    <v-col cols="12">
                      <v-switch
                        hide-details
                        v-model="customer.notificationsCheckins"
                        @change="updateField('NotificationsCheckins', $event)"
                        label="Email Notifications"
                        class="reverted me-2"
                        :loading="NotificationsCheckinsLoading"
                      />

                      <v-switch
                        hide-details
                        v-model="customer.notificationsSMS"
                        @change="updateField('NotificationsSMS', $event)"
                        label="SMS Notifications"
                        class="reverted me-2"
                        :loading="NotificationsSMSLoading"
                      />

                      <v-switch
                        hide-details
                        v-model="customer.notificationsPush"
                        @change="updateField('NotificationsPush', $event)"
                        label="Push Notifications"
                        class="reverted me-2"
                        :loading="NotificationsPushLoading"
                      />
                    </v-col>
                    <v-col align="center" class="my-4">
                      <p class="text-h6 grey--text">Need to get in touch?</p>
                      <a
                        target="_blank"
                        href="https://support.ownli.co/hc/en-us/requests/new"
                        class="text-decoration-none"
                        >Contact us</a
                      >&nbsp;&nbsp;&nbsp;<a @click="confirmDeleteDialog = true"
                        >Delete my account</a
                      >
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-card flat full-width fill-height class="card-center" color="transparent">
      <v-card-title class="pa-0">
        <v-avatar size="6rem">
          <v-img :src="user.photo" v-if="user && user.photo" />
          <BrandLogo v-else />
        </v-avatar>
        <h3>My Profile</h3>
      </v-card-title>

      <v-card-text>
        <p class="opacity mt-2">
          This information will NOT be shared <br />
          without your explicit consent
        </p>
      </v-card-text>

      <v-card-text class="pt-0 added-bottom-pad">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="6">
              <v-text-field
                filled
                v-model="customer.nameFirst"
                :rules="nameRules"
                disabled
                label="First name"
                append-icon="$vuetify.icons.global_required"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                filled
                v-model="customer.nameLast"
                :rules="nameRules"
                disabled
                label="Last name"
                append-icon="$vuetify.icons.global_required"
              />
            </v-col>

            <v-col cols="4">
              <v-select
                filled
                v-model="countryCode"
                item-text="label"
                item-value="value"
                :items="countries"
                :rules="requiredRule"
                disabled
                label="Country"
              >
              </v-select>
            </v-col>

            <v-col cols="8">
              <v-text-field
                filled
                v-model="customer.contactPhone"
                disabled
                label="Mobile number"
                type="tel"
                append-icon="$vuetify.icons.global_required"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                filled
                :value="user.email"
                :rules="emailRules"
                required
                disabled
                :label="loginEmailLabel"
                type="email"
                :append-icon="loginEmailIcon"
              />
            </v-col>
          </v-row>

          <div class="mb-5">
            <h3>Check-in Notifications</h3>
          </div>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field
                filled
                v-model="customer.contactEmail"
                :rules="emailRules"
                required
                label="Primary e-mail used for notifications"
                type="email"
                append-icon="$vuetify.icons.global_required"
              />
            </v-col>

            <v-col cols="12" class="mb-10">
              <v-switch
                inset
                hide-details
                v-model="customer.notificationsCheckins"
                @change="_handleNotificationsToggle"
                label="Email Notifications"
                color="secondary"
                class="reverted mt-0"
              />

              <v-switch
                inset
                hide-details
                v-model="customer.notificationsSMS"
                @change="_handleNotificationsToggle"
                label="SMS Notifications"
                color="secondary"
                class="reverted"
              />

            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="mb-2">
                Need any help?
                <a
                  target="_blank"
                  href="https://joinparked.zendesk.com/hc/en-us/requests/new"
                  >Contact us</a
                >
              </div>
              <v-btn
                x-large
                block
                color="primary"
                elevation="8"
                @click="submit"
                :loading="loading"
              >
                submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-dialog v-model="confirmationDialog" persistent max-width="350px">
        <v-card class="card-center">
          <v-card-title class="text-h5"> Are you sure? </v-card-title>

          <v-card-text>
            This means you’ll miss out on the reminders to check-in and earn the
            Amazon rewards.
          </v-card-text>

          <v-card-text>
            <v-btn
              block
              large
              color="primary"
              class="mb-2"
              @click="_switchNotificationsOn"
            >
              NO
            </v-btn>
            <v-btn
              block
              large
              color="primary"
              outlined
              @click="_switchNotificationsOff"
            >
              YES
            </v-btn>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card> -->
    <v-dialog v-model="verifyOtpDialog" persistent max-width="400">
      <v-dialog-transition>
        <v-card
          class="py-8 px-6 text-center"
          elevation="12"
          width="100%"
          min-height="400"
        >
          <h3 class="text-h6 mb-4">
            {{
              varificationStatus === "email"
                ? "Email verification"
                : "Phone number verification"
            }}
          </h3>

          <template v-if="otpLoading">
            <div class="text-body-2">
              Sending code to <br />

              <b>{{
                varificationStatus === "email"
                  ? customer.contactEmail
                  : customer.contactPhone
              }}</b>
            </div>
            <v-progress-circular
              class="my-4"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </template>
          <template v-else>
            <div class="text-body-2">
              Enter the code sent to <br />

              <b>{{
                varificationStatus === "email"
                  ? customer.contactEmail
                  : customer.contactPhone
              }}</b>
            </div>

            <v-otp-input
              class="mt-6 mb-4"
              v-model="verificationCode"
              type="number"
              length="5"
            ></v-otp-input>
            <span v-if="verifyCodeError"
              >Error verifying code. Make sure the code is not expired and is
              entered correctly. To receive a new code click cancel and
              retry.</span
            >
            <v-card-actions class="flex-column">
              <v-btn
                width="150"
                color="primary"
                class="ma-0 mb-3"
                @click="verifyCode"
                :loading="verifyLoading"
                :disabled="verificationCode?.length !== 5"
                >verify</v-btn
              >
              <v-btn
                width="150"
                class="ma-0"
                @click="
                  verifyOtpDialog = false;
                  verifyCodeError = false;
                "
                >Cancel</v-btn
              >
            </v-card-actions>
          </template>
        </v-card>
      </v-dialog-transition>
    </v-dialog>
    <v-dialog v-model="confirmDeleteDialog" max-width="400">
      <v-dialog-transition>
        <v-card class="py-8 px-6 text-center" elevation="12" width="100%">
          <p>
            Sorry to see you go. Please submit the deletion request. It takes up
            to 3 business days to confirm.
          </p>
          <v-btn color="primary" class="mt-6" @click="deleteAccount"
            >Delete my account</v-btn
          >
        </v-card>
      </v-dialog-transition>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import api from "@/services/api-service";

export default {
  name: "Profile",

  computed: {
    ...mapGetters(["user", "customer", "partner"]),

    policy() {
      return this.customer && this.customer.policy;
    },

    countryCode() {
      return this.policy.discountsProviderCountryCode;
    },

    authProvider() {
      return this.user && this.user.provider;
    },

    loginEmailLabel() {
      switch (this.authProvider) {
        default:
          return "Login E-mail";

        case "google.com":
          return "Google account Login";

        case "facebook.com":
          return "Facebook account Login";

        case "apple.com":
          return "Apple account Login";
      }
    },
    loginEmailIcon() {
      switch (this.authProvider) {
        default:
          return "$vuetify.icons.auth_email";

        case "google.com":
          return "$vuetify.icons.auth_google";

        case "facebook.com":
          return "$vuetify.icons.auth_facebook";

        case "apple.com":
          return "$vuetify.icons.auth_apple";
      }
    },

    maxNumberOfVehiclesOnPolicy() {
      let items = [];
      for (
        let index = 1;
        index <= this.policy.discountsProviderMaxVehiclesPerPolicy;
        index++
      ) {
        items.push({
          text: `${index}`,
          value: index,
        });
      }

      return items;
    },
    memberSince() {
      const date = new Date(this.customer.dateRegisteredAt);
      const options = { year: "numeric", month: "long" };
      return `Member since: ${date.toLocaleDateString("en-US", options)}`;
    },
  },

  data() {
    return {
      loading: false,
      loaded: false,
      valid: false,
      verifyCodeError: false,
      varificationStatus: "email",
      verifyOtpDialog: false,
      confirmDeleteDialog: false,
      verifyLoading: false,
      otpLoading: true,
      verificationCode: null,
      confirmationDialog: false,
      zipcodeFocus: false,
      numberOfChild: false,
      emailFocus: false,
      GenderLoading: false,
      EthnicityLoading: false,
      AgeRangeLoading: false,
      StateLoading: false,
      ZipcodeLoading: false,
      ContactEmailLoading: false,
      EducationLevelLoading: false,
      EmploymentStatusLoading: false,
      IncomeLoading: false,
      RelationshipStatusLoading: false,
      NumberChildrenLoading: false,
      HomeOwnershipLoading: false,
      HomeTypeLoading: false,
      NotificationsSMSLoading: false,
      NotificationsPushLoading: false,
      NotificationsCheckinsLoading: false,
      lookups: null,

      countries: [
        {
          label: "+1",
          value: "US",
        },
      ],

      states: [
        {
          name: "Alabama",
          code: "us-al",
        },
        {
          name: "Alaska",
          code: "us-ak",
        },
        {
          name: "Arizona",
          code: "us-az",
        },
        {
          name: "Arkansas",
          code: "us-ar",
        },
        {
          name: "California",
          code: "us-ca",
        },
        {
          name: "Colorado",
          code: "us-co",
        },
        {
          name: "Connecticut",
          code: "us-ct",
        },
        {
          name: "Delaware",
          code: "us-de",
        },
        {
          name: "District of Columbia",
          code: "us-dc",
        },
        {
          name: "Florida",
          code: "us-fl",
        },
        {
          name: "Georgia",
          code: "us-ga",
        },
        {
          name: "Hawaii",
          code: "us-hi",
        },
        {
          name: "Idaho",
          code: "us-id",
        },
        {
          name: "Illinois",
          code: "us-il",
        },
        {
          name: "Indiana",
          code: "us-in",
        },
        {
          name: "Iowa",
          code: "us-ia",
        },
        {
          name: "Kansas",
          code: "us-ks",
        },
        {
          name: "Kentucky",
          code: "us-ky",
        },
        {
          name: "Louisiana",
          code: "us-la",
        },
        {
          name: "Maine",
          code: "us-me",
        },
        {
          name: "Maryland",
          code: "us-md",
        },
        {
          name: "Massachusetts",
          code: "us-ma",
        },
        {
          name: "Michigan",
          code: "us-mi",
        },
        {
          name: "Minnesota",
          code: "us-mn",
        },
        {
          name: "Mississippi",
          code: "us-ms",
        },
        {
          name: "Missouri",
          code: "us-mo",
        },
        {
          name: "Montana",
          code: "us-mt",
        },
        {
          name: "Nebraska",
          code: "us-ne",
        },
        {
          name: "Nevada",
          code: "us-nv",
        },
        {
          name: "New Hampshire",
          code: "us-nh",
        },
        {
          name: "New Jersey",
          code: "us-nj",
        },
        {
          name: "New Mexico",
          code: "us-nm",
        },
        {
          name: "New York",
          code: "us-ny",
        },
        {
          name: "North Carolina",
          code: "us-nc",
        },
        {
          name: "North Dakota",
          code: "us-nd",
        },
        {
          name: "Ohio",
          code: "us-oh",
        },
        {
          name: "Oklahoma",
          code: "us-ok",
        },
        {
          name: "Oregon",
          code: "us-or",
        },
        {
          name: "Pennsylvania",
          code: "us-pa",
        },
        {
          name: "Rhode Island",
          code: "us-ri",
        },
        {
          name: "South Carolina",
          code: "us-sc",
        },
        {
          name: "South Dakota",
          code: "us-sd",
        },
        {
          name: "Tennessee",
          code: "us-tn",
        },
        {
          name: "Texas",
          code: "us-tx",
        },
        {
          name: "Utah",
          code: "us-ut",
        },
        {
          name: "Vermont",
          code: "us-vt",
        },
        {
          name: "Virginia",
          code: "us-va",
        },
        {
          name: "Washington",
          code: "us-wa",
        },
        {
          name: "West Virginia",
          code: "us-wv",
        },
        {
          name: "Wisconsin",
          code: "us-wi",
        },
        {
          name: "Wyoming",
          code: "us-wy",
        },
      ],

      requiredRule: [(v) => !!v || "Field is required"],
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length <= 20) || "Name must be less than 10 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => (v && /.+@.+/.test(v)) || "E-mail must be valid",
      ],
      phoneRules: [(v) => !!v || "Phone is required"],
      zipcodeRules: [(v) => (v && v.length == 5) || "Zipcode is invalid"],
      numberChildrenRules: [
        (v) => (v > 0 && v < 10) || "Number of children must between 0 and 10",
      ],
    };
  },

  methods: {
    _handleNotificationsToggle() {
      if (!this.customer.notificationsCheckins) {
        this.confirmationDialog = true;
      }
    },
    _switchNotificationsOff() {
      this.customer.notificationsCheckins = false;
      this.confirmationDialog = false;
    },
    _switchNotificationsOn() {
      this.customer.notificationsCheckins = true;
      this.confirmationDialog = false;
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return null;
      }

      try {
        this.loading = true;
        const request = {
          NameFirst: this.customer.nameFirst,
          NameLast: this.customer.nameLast,
          ContactEmail: this.customer.contactEmail,
          ContactPhone: this.customer.contactPhone,
          NotificationsNewsletter: this.customer.notificationsNewsletter,
          NotificationsCheckins: this.customer.notificationsCheckins,
        };
        // Put customer's profile data:
        const response = await api.put(
          `customers/${this.customer.id}`,
          request
        );
        if (response.status == 202 && response.data) {
          this.$store.commit("UPDATE_CUSTOMER", response.data);
          this.$router.replace("/vehicles");
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "submit",
          },
          error
        );
      } finally {
        this.loading = false;
      }
    },
    updateNumberChildren() {
      if (!this.customer?.numberChildren) {
        return;
      }
      this.updateField("NumberChildren", this.customer.numberChildren);
    },
    updateZipcode() {
      if (!this.customer?.zipcode) {
        return;
      }
      this.updateField("Zipcode", this.customer.zipcode);
    },
    updateContactEmail() {
      if (!this.customer?.contactEmail) {
        return;
      }
      this.updateField("ContactEmail", this.customer.contactEmail);
    },
    async updateField(field, value) {
      try {
        this[field + "Loading"] = true;
        const url = `customers/${this.customer.id}/update-field`;
        const params = { field: field, [field]: value };
        const response = await api.put(url, params);
        if (response.data) {
          const updatedValue = response.data;
          for (const key in updatedValue) {
            if (updatedValue[key] === null) {
              delete updatedValue[key];
            }
          }
          this.$store.commit(
            "UPDATE_CUSTOMER",
            Object.assign(this.customer, updatedValue)
          );
        }
        this[field + "Loading"] = false;
      } catch (error) {
        this[field + "Loading"] = false;
      }
    },
    async getLookupTable() {
      const url = "lookup/all-lookups";
      const response = await api.get(url);
      if (response.data) {
        const groupedLookupValues = {};
        let table = response.data;
        this.states.forEach((e) => {
          table.push({
            lookupType: "State",
            lookupKey: e.name,
            description: e.name,
          });
        });
        response.data.forEach((lookupValue) => {
          const { lookupType, ...rest } = lookupValue;
          if (!groupedLookupValues[lookupType]) {
            groupedLookupValues[lookupType] = [];
          }
          groupedLookupValues[lookupType].push(rest);
        });
        this.lookups = groupedLookupValues;
      }
    },
    async sendCode(type = 0) {
      if (type == 1) {
        this.varificationStatus = "phone";
      } else {
        this.varificationStatus = "email";
      }
      this.verifyOtpDialog = true;
      this.otpLoading = true;
      const url = "customer/verification/send";
      const payload = {
        method: type == 0 ? 10 : 20,
        customerId: this.customer.id,
      };
      const response = await api.post(url, payload);
      this.otpLoading = false;
    },
    async verifyCode() {
      if (this.verificationCode.length !== 5) {
        return;
      }
      this.verifyLoading = true;
      const url = "customer/verification/verify";
      const payload = {
        method: this.varificationStatus == "email" ? 10 : 20,
        customerId: this.customer.id,
        code: this.verificationCode,
      };
      const response = await api.post(url, payload);
      console.log("response", response);
      this.verifyLoading = false;
      if (response.data.status === "CodeVerified") {
        this.verifyOtpDialog = false;
      } else {
        this.verifyCodeError = true;
      }
    },
    deleteAccount() {
      const url = this.customer.contactEmail;
      window.open(
        `https://support.ownli.co/hc/en-us/requests/new?requester_email=${encodeURI(
          url
        )}`,
        "_blank"
      );
      this.confirmDeleteDialog = false;
    },
  },
  mounted() {
    this.getLookupTable();
  },
};
</script>

<style scoped>
.v-input--switch >>> .v-input__slot {
  flex-flow: row-reverse;
}

.v-input--switch >>> .v-input__slot .v-input--selection-controls__input {
  margin-right: 0;
  margin-left: 1rem;
}
.gap-2 {
  gap: 6px;
}
</style>
