<template>
  <div id="rewards-summary-container">
    <!-- <div
      class="confetti-bck-wrapper"
      v-if="
        summary.total &&
        (policy.status == 'Pending' || policy.status == 'Activated')
      "
    >
      <RewardsConfettiSVG />
    </div> -->

    <v-card
      id="summary"
      class="card-center bg-primary-light"
      :loading="loading"
    >
      <v-card-text class="pt-6 pb-0 align-start">
        <!-- <h2>Total earned: {{ summary.total | currency(currency, 2) }}</h2> -->
        <h2 class="text-normal text-white">Balance</h2>
        <span class="text-white"
          >Member since
          {{ customer.dateRegisteredAt | moment("MMM, YYYY") }}</span
        >
      </v-card-text>

      <v-card-text class="align-start">
        <span class="text-white">Earned (including Unclaimed)</span>
        <div class="d-flex align-center">
          <h2 class="text-white">
            {{ summary.total | currency(currency, 2) }}
            <v-icon
              @click="openRewardsModal('Total')"
              class="cursor-pointer"
              color="white"
              small
              >$vuetify.icons.open_new</v-icon
            >
          </h2>
        </div>
        <div class="d-flex mt-4">
          <div class="d-flex flex-column align-start">
            <span class="text-white">Unclaimed</span>
            <div class="d-flex align-center">
              <h3 class="text-white">
                {{ summary.confirmed | currency(currency, 2) }}
              </h3>
              <v-icon
                @click="openRewardsModal('Unclaimed')"
                class="ml-1 cursor-pointer"
                color="white"
                x-small
                >$vuetify.icons.open_new</v-icon
              >
            </div>
          </div>
          <v-divider color="white" class="mx-8" vertical></v-divider>
          <div class="d-flex flex-column align-start">
            <span class="text-white">Pending</span>
            <div class="d-flex align-center">
              <h3 class="text-white">
                {{ summary.pending | currency(currency, 2) }}
              </h3>
              <v-icon
                @click="openRewardsModal('Pending')"
                class="ml-1 cursor-pointer"
                color="white"
                x-small
                >$vuetify.icons.open_new</v-icon
              >
            </div>
          </div>
        </div>
      </v-card-text>
      <!-- <v-card-text v-else>
          <h2 class="opacity">
            Pending approval:{{ summary.pending | currency(currency, 2) }}
          </h2>
          <span class="opacity mt-2">Takes up to 1 business day</span>
        </v-card-text> -->

      <!-- <v-card-text class="pt-15 mt-5" v-else-if="policy.status != 'Declined'">
        <h1 class="opacity-low">Validating policy</h1>
        <h3 class="opacity mt-4 font-weight-regular">
          Takes up to 1 business day
        </h3>
      </v-card-text>

      <v-card-text class="pt-15 mt-5" v-else>
        <h1 class="opacity-low">Policy Declined</h1>
        <h4 class="opacity mt-4 font-weight-regular">
          Please contact us if you have any questions.
        </h4>
        <div class="mt-2">
          <a
            target="_blank"
            href="https://joinparked.zendesk.com/hc/en-us/requests/new"
            >Contact us</a
          >
        </div>
      </v-card-text> -->
      <v-img class="card-corner-img" src="/assets/buyers/stacked.png"></v-img>
    </v-card>
    <v-card-text class="light-grey text-h6 font-weight-regular pa-0 my-6"
      >Please confirm your
      <a class="blue--text" @click="$router.push('profile')">contact info </a>
      in order to securely receive your rewards.</v-card-text
    >
    <v-card-text class="d-flex align-center justify-center">
      <v-btn
        large
        :loading="loading"
        color="primary"
        elevation="8"
        class="px-10"
        :disabled="disableClaim"
        to="/rewards-claim"
      >
        Claim rewards
      </v-btn>
    </v-card-text>
    <v-card-text class="pa-0"
      >Minimum to claim is $1 and requires verified
      <a class="blue--text" @click="$router.push('profile')">email </a> and
      <a class="blue--text" @click="$router.push('profile')"
        >cell phone.
      </a></v-card-text
    >
    <v-card-text class="light-grey text-h6 font-weight-regular pa-0 my-6"
      >Checkout the
      <a class="blue--text" @click="$router.replace('marketplace')"
        >Marketplace
      </a>
      to see what else you can earn or start adding more
      <a class="blue--text" @click="$router.replace('home-card')">Data </a>
      now.</v-card-text
    >

    <!-- <template v-if="summary && policy.status != 'Declined'">
      <v-card
        flat
        color="transparent"
        class="card-center"
        v-if="policy.numberOfVehiclesActivated == 0"
      >
        <v-card-text class="pa-8">
          <RewardsCarSVG
            class="floating-svg"
            :class="{ show: !loading }"
            :color="$vuetify.theme.currentTheme.accent"
          />
        </v-card-text>
        <v-card-text class="py-0">
          <v-btn
            large
            :loading="loading"
            color="primary"
            elevation="8"
            class="px-10"
            to="/vehicle/activate"
          >
            check-in now!
          </v-btn>
          <span class="mt-5">Not by your car now?</span>
          <ReminderButton
            text
            :title="title"
            :description="description"
            :start="start"
            id="btn-reminder"
            class="text-initial"
          >
            Set a calendar reminder
          </ReminderButton>
        </v-card-text>
      </v-card>

      <v-card flat color="transparent" class="card-center" v-else>
        <v-card-text class="pa-8">
          <RewardsGiftSVG
            class="floating-svg"
            :class="{ show: !loading }"
            :color="$vuetify.theme.currentTheme.accent"
          />
        </v-card-text>
        <v-card-text class="py-0">
          <v-btn
            large
            :loading="loading"
            color="primary"
            elevation="8"
            class="px-10"
            :disabled="disableClaim"
            to="/rewards-claim"
          >
            Claim rewards
          </v-btn>
        </v-card-text>
      </v-card>
    </template> -->

    <!-- SHOW REWARDS MODAL -->
    <v-dialog v-model="customizeSponserModal" fullscreen hide-overlay>
      <v-card>
        <v-toolbar elevation="0">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="primary" text @click="customizeSponserModal = false">
              Close
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row
          v-if="customizeSponserModalLoading"
          justify="center"
          align="center"
        >
          <v-col align="center" cols="12" sm="8" md="6">
            <v-progress-circular
              align="center"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <template v-else>
          <v-container class="sponser-modal-container">
            <div class="d-flex flex-column align-center justify-center">
              <h2 class="text-uppercase">Your rewards</h2>
              <template v-if="!totalRewards?.length">
                <span v-if="rewardType == 'Total'">No Earned rewards.</span>
                <span v-else-if="rewardType == 'Unclaimed'"
                  >No Unclaimed rewards.</span
                >
                <span v-else>No Pending rewards.</span>
              </template>
            </div>

            <v-card
              class="mt-2"
              v-for="(reward, index) in totalRewards"
              :key="'r' + index"
              @click="openBuyerModal(reward.buyerId)"
            >
              <v-list-item>
                <v-list-item-avatar size="50" class="rounded-0">
                  <v-img :src="reward?.logo" contain></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">{{
                    reward?.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle class="text-wrap text-body-1">
                    {{ reward?.buyerDescription }}
                  </v-list-item-subtitle>
                  <h4 class="mt-1 success--text">
                    {{ reward.amount | currency(currency, 2) }}
                  </h4>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-container>
        </template>
      </v-card>
    </v-dialog>

    <!-- Buyer modal -->
    <v-dialog v-if="activeBuyerId" v-model="buyerModal" fullscreen hide-overlay>
      <buyer-details
        @dismiss="dismiss"
        :buyerId="activeBuyerId"
      ></buyer-details>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import api from "@/services/api-service";
import RewardsConfettiSVG from "@/components/customer/RewardsConfettiSVG";
import RewardsCarSVG from "@/components/customer/RewardsCarSVG";
import RewardsGiftSVG from "@/components/customer/RewardsGiftSVG";
import ReminderButton from "@/components/global/ReminderButton";
import BuyerDetails from "@/components/customer/BuyerDetails.vue";

export default {
  name: "RewardsSummary",

  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  components: {
    RewardsConfettiSVG,
    RewardsCarSVG,
    RewardsGiftSVG,
    ReminderButton,
    BuyerDetails,
  },

  computed: {
    ...mapGetters(["connection", "offline"]),
    isWeakSignal() {
      return this.connection != "3g" && this.connection != "4g";
    },

    disableClaim() {
      return (
        !this.summary.confirmed 
      );
    },

    policy() {
      return this.customer.policy;
    },
    currency() {
      return this.policy.discountsProviderCurrency;
    },
    title() {
      return `Ownli - Start your vehicle Check-in`;
    },
    description() {
      return "Keep engaged with your policy check-ins reward on Ownli, <a href='https://www.ownli.co/'>Click to Check-in</a>";
    },
    start() {
      let date = this.$options.filters.moment(
        new Date(),
        "add",
        "2 hours",
        "YYYY-MM-DD HH:MM"
      );
      return this.$options.filters.moment(date, "YYYY-MM-DD HH:MM");
    },
  },

  data() {
    return {
      loading: false,
      dialog: false,
      summary: {
        total: 0,
        confirmed: 0,
        pending: 0,
      },
      customizeSponserModal: false,
      customizeSponserModalLoading: true,
      totalRewards: [],
      rewardType: null,
      activeBuyerId: null,
      buyerModal: false,
    };
  },

  methods: {
    async load() {
      try {
        this.loading = true;
        // Fetch customer by login e-mail:
        const uri = `/policies/${this.policy.id}/rewards-summary`;
        const response = await api.get(uri, {
          requestId: uri,
        });

        if (response.status == 200 && response.data) {
          this.summary = response.data;
        }
      } catch (error) {
        this.$Logger.error(
          {
            component: this.$options.name,
            method: "load",
          },
          error
        );
      } finally {
        this.loading = false;
      }
    },
    async openRewardsModal(type = "Total") {
      this.rewardType = type;
      this.customizeSponserModal = true;
      this.customizeSponserModalLoading = true;
      const url = `customers/${this.customer.id}/rewards`;
      const response = await api.get(url, {
        requestId: url,
      });
      if (response?.data?.length) {
        switch (type) {
          case "Pending":
            this.totalRewards = response.data.filter(
              (r) => r.status === "Pending"
            );
            break;
          case "Unclaimed":
            this.totalRewards = response.data.filter(
              (r) => r.status === "Confirmed"
            );
            break;
          case "Total":
            this.totalRewards = response.data.filter(
              (r) => r.status === "Confirmed" || r.status === "Claimed"
            );
            break;

          default:
            break;
        }
      }
      this.customizeSponserModalLoading = false;
    },
    openBuyerModal(buyerId) {
      this.activeBuyerId = buyerId;
      this.buyerModal = true;
    },
    dismiss() {
      this.activeBuyerId = null;
      this.buyerModal = false;
    },
  },

  mounted() {
    this.load();
  },
};
</script>

<style scoped>
.bg-primary-light {
  background: rgba(123, 97, 255, 1);
}
.text-white {
  color: #fff;
}
.text-normal {
  font-weight: normal;
}
.light-grey {
  color: rgba(114, 114, 114, 1);
}
.card-corner-img {
  position: absolute;
  right: 8px;
  bottom: 0;
  max-width: 100px;
}
.confetti-bck-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  overflow: hidden;
  width: 100%;
  align-items: center;
  pointer-events: none;
}

.confetti-bck-wrapper > svg {
  width: 100%;
}

#summary {
  padding-bottom: 3rem;
}
.cursor-pointer {
  cursor: pointer;
}

.floating-svg {
  height: 100px;
  max-width: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
  opacity: 0;
  transition: 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.floating-svg.show {
  opacity: 1;
  top: -52px;
  transition: 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#btn-reminder {
  text-transform: initial !important;
  font-weight: normal;
  height: auto;
}
</style>
