<template>
  <v-main>
    <navbar-top-with-back-btn
      class="top-header"
      :headerTitle="headerTitle"
    ></navbar-top-with-back-btn>
    <div class="scroll-container">
      <v-row v-if="loading" justify="center" align="center">
        <v-col align="center" cols="12" sm="8" md="6">
          <v-progress-circular
            align="center"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-container class="px-6" v-if="survey">
        <v-list color="transparent">
          <h3 class="text-center primary--text">Sponsored By:</h3>
          <v-list-item class="px-0">
            <v-list-item-avatar size="50" class="rounded-0">
              <v-img :src="survey?.sponsor?.listLogo"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h6">{{
                survey?.sponsor?.name
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-wrap text-body-1">
                {{ survey?.sponsor?.shortDescription }}
              </v-list-item-subtitle>
              <v-btn
                class="learn-more-btn pa-0 text-capitalize"
                text
                @click="getBuyerModalDiscount"
                :ripple="false"
                color="primary"
                >Learn More</v-btn
              >
            </v-list-item-content>
          </v-list-item>
          <p class="text-body-1 text-justify">{{ survey?.description }}</p>
          <v-card
            :key="item.id"
            v-for="item in survey?.questions"
            color="transparent"
            class="border-primary rounded-0 mb-3"
            outlined
          >
            <h3 class="py-2 px-3">
              {{ item.text }}
              <span v-if="item.isRequired" class="error--text">*</span>
            </h3>
            <v-container
              v-if="item.type === 'Select_Multi'"
              class="survey-checkbox-container"
            >
              <v-checkbox
                class="custom-checkbox"
                v-for="ans in item.answers"
                :key="ans.id"
                :label="ans.text"
                :value="ans.id"
                hide-details
                v-model="formData[item.id]"
                multiple
                :disabled="!!completedSurvey"
              ></v-checkbox>
            </v-container>
            <v-container
              class="survey-radio-container"
              v-if="item.type === 'Select_Single'"
            >
              <v-radio-group
                :disabled="!!completedSurvey"
                hide-details
                v-model="formData[item.id]"
              >
                <v-radio
                  :key="ans.id"
                  :value="ans.id"
                  v-for="ans in item.answers"
                  :label="ans.text"
                ></v-radio>
              </v-radio-group>
            </v-container>
            <v-container v-if="item.type === 'OpenText'">
              <v-text-field
                v-model="formData[item.id]"
                hide-details
                outlined
                :disabled="!!completedSurvey"
                class="rounded-0 mb-3"
              ></v-text-field>
            </v-container>
          </v-card>
        </v-list>
      </v-container>
    </div>
    <v-footer v-if="!completedSurvey" app outlined>
      <v-container class="d-flex flex-column pa-0">
        <v-row>
          <v-col align="center" class="mt-2 pb-0">
            <v-btn
              color="primary"
              :disabled="!allRequiredFieldsFilled || submitting"
              @click="submit"
              :loading="submitting"
              >Finish</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center" class="mb-2">
            <!-- <a
            @click="openSponserCustomiseModal"
            class="text-decoration-underline text-uppercase text-body-2"
            >Choose which companies can buy your info</a
          > -->
            <buyer-preferences-link
              event-type="SURVEY_CHECKIN"
              :extra-data="{ surveyTemplateId: survey?.id }"
              @open-buyers-modal="customizeSponserModal = true"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-footer>

    <!-- learn more modal -->
    <v-dialog v-model="learnMoreModal" fullscreen hide-overlay>
      <buyer-details
        @dismiss="learnMoreModal = false"
        :buyerId="sponsorId"
      ></buyer-details>
    </v-dialog>

    <buyers-list-modal
      v-model="customizeSponserModal"
      event-type="SURVEY_CHECKIN"
      :extra-data="{ surveyTemplateId: survey?.id }"
      @close="customizeSponserModal = false"
    />

    <!-- Survey done modal -->
    <v-dialog v-model="surveyDoneModal" fullscreen hide-overlay>
      <survey-done
        v-if="survey && submittedSurveyId"
        :surveySubmitRewards="surveySubmitRewards"
        :surveyTemplate="survey"
        :surveyId="submittedSurveyId"
      ></survey-done>
      <v-row v-else justify="center" align="center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-dialog>
  </v-main>
</template>
<script>
import RewardCard from "@/components/customer/RewardCard";
import BuyerDetails from "@/components/customer/BuyerDetails";
import { mapGetters } from "vuex";
import NavbarTopWithBackBtn from "@/components/appshell/NavbarTopWithBackBtn.vue";
import BuyerPreferencesLink from "@/components/global/BuyerPreferencesLink.vue";
import BuyersListModal from "@/components/global/BuyersListModal.vue";

import api from "@/services/api-service";
import SurveyDone from "./SurveyDone.vue";
export default {
  name: "SurveyDetails",
  components: {
    RewardCard,
    NavbarTopWithBackBtn,
    SurveyDone,
    BuyerDetails,
    BuyerPreferencesLink,
    BuyersListModal,
  },
  computed: {
    ...mapGetters(["customer"]),
    headerTitle() {
      return this.survey?.title || "Loading Survey";
    },
    allRequiredFieldsFilled() {
      if (this.survey?.questions?.length) {
        return Object.values(this.survey.questions).every((question) => {
          if (
            typeof this.formData[question.id] === "object" &&
            !this.formData[question.id]?.length
          ) {
            return;
          }
          return !question.isRequired || !!this.formData[question.id];
        });
      }
    },
  },
  data() {
    return {
      loading: true,
      submitting: false,
      formData: {},
      completedSurvey: null,
      learnMoreModal: false,
      sponsorId: "",
      customizeSponserModal: false,
      customizeSponserModalOld: false,
      modalLoading: true,
      currentTab: "details",
      surveyDoneModal: false,
      survey: null,
      buyerDiscount: null,
      surveySubmitRewards: [],
      submittedSurveyId: null,
      customerDataBuyerPreferences: {
        enableAllDataBuyers: false,
      },
    };
  },
  props: ["id"],
  methods: {
    navigate(id) {
      console.log("ev", ev);
    },
    async getSurvey() {
      try {
        const url = `survey-templates/v1/${this.$route.params.id}`;
        const response = await api.get(url, {
          requestId: url,
        });
        this.survey = response.data;
        if (this.$route?.query?.isSurveyFilled) {
          const url = `surveys/v1/${this.$route?.query?.isSurveyFilled}`;
          const response = await api.get(url, {
            requestId: url,
          });
          this.completedSurvey = response.data;
          this.preFillFormData();
        }
        await this.$nextTick();
      } catch (error) {
        console.error("Error fetching survey details:", error);
      } finally {
        this.loading = false;
      }
    },
    async submit() {
      this.submitting = true;
      const plainFormData = JSON.parse(JSON.stringify(this.formData));
      const payload = {
        customerId: this.customer.id,
        surveyTemplateId: this.survey.id,
        answers: [],
      };

      this.survey.questions.forEach((question) => {
        if (
          question.type === "Select_Single" ||
          question.type === "Select_Multi"
        ) {
          if (plainFormData[question.id]) {
            if (Array.isArray(plainFormData[question.id])) {
              plainFormData[question.id].forEach((answerId) => {
                payload.answers.push({
                  surveyTemplateQuestionId: question.id,
                  surveyTemplateAnswerId: answerId,
                });
              });
            } else {
              payload.answers.push({
                surveyTemplateQuestionId: question.id,
                surveyTemplateAnswerId: plainFormData[question.id],
              });
            }
          }
        } else if (question.type === "OpenText") {
          if (plainFormData[question.id]) {
            payload.answers.push({
              surveyTemplateQuestionId: question.id,
              answerText: plainFormData[question.id],
            });
          }
        }
      });
      const url = "/surveys/v1";
      const response = await api.post(url, payload);
      this.loading = false;
      this.submitting = false;
      this.submittedSurveyId = response.data.id; // Store the survey ID
      this.surveyDoneModal = true;
    },

    preFillFormData() {
      if (this.completedSurvey && this.completedSurvey.records) {
        this.completedSurvey.records.forEach((record) => {
          if (record.type === "Select_Single") {
            const selectedAnswer = record.answers.find(
              (answer) => answer.selected
            );
            if (selectedAnswer) {
              this.$set(this.formData, record.id, selectedAnswer.id);
            }
          } else if (record.type === "Select_Multi") {
            const selectedAnswers = record.answers.filter(
              (answer) => answer.selected
            );
            if (selectedAnswers.length > 0) {
              this.$set(
                this.formData,
                record.id,
                selectedAnswers.map((answer) => answer.id)
              );
            }
          } else if (record.type === "OpenText") {
            this.$set(this.formData, record.id, record.answerText);
          }
        });
      }
    },
    async getBuyerModalDiscount() {
      this.sponsorId = this.survey.sponsor.id;
      this.learnMoreModal = true;
    },
  },
  async created() {
    await this.getSurvey();
  },
  mounted() {
    console.log("mounted");
  },
};
</script>
<style scoped>
.top-header {
  position: sticky;
  top: 0;
  z-index: 1;
}
.learn-more-btn {
  max-width: fit-content;
}
.border-primary {
  border-color: rgba(123, 97, 255, 0.267) !important;
}
.video-wrapper {
  width: 100%;
  max-height: 250px;
}
.borderless-table th,
.borderless-table td,
.borderless-table tr {
  border-bottom: none !important;
}
.borderless-table tbody th,
.borderless-table tbody td,
.borderless-table tbody tr {
  height: auto !important;
}
.fs-18 {
  font-size: 18px;
}
.scroll-container {
  height: calc(100vh - 56px - 56px); /* viewport height - header - footer */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* Important for iOS */
  padding-top: 10px;
}
</style>
