// BuyersList.vue
<template>
  <div class="buyers-list">
    <v-container>
      <span class="text-body-1">
        You're in control. Choose which companies can buy your info.
      </span>

      <v-switch
        v-if="!loading"
        v-model="acceptAll"
        class="mt-6 mb-8"
        hide-details
        label="Maxmimize my earnings."
        :ripple="false"
        :loading="saving"
        @change="saveAcceptAll"
      />

      <p v-if="!loading" class="text-body-1 mb-4">
        Rewards are only given by your selected buyers.
      </p>

      <v-card
        v-for="(discount, index) in discounts"
        :key="index"
        class="mb-3 buyer-card"
        outlined
      >
        <v-list-item>
          <v-list-item-avatar size="50" class="rounded-0">
            <v-img :src="discount.buyer.listLogo" contain />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              class="text-h6 primary--text"
              style="cursor: pointer;"
              @click="openBuyerDetails(discount.buyer.id)"
            >
              {{ discount.buyer.name }}
            </v-list-item-title>

            <v-list-item-subtitle class="text-wrap text-body-1">
              {{ discount.buyer.shortDescription }}
            </v-list-item-subtitle>

            <h4 class="mt-1 success--text">${{ getRewardAmount(discount) }}</h4>
          </v-list-item-content>

          <v-list-item-action v-if="!acceptAll">
            <v-switch
              :input-value="getBuyerStatus(discount.buyer.id)"
              :loading="saving"
              @change="(val) => saveBuyerPreference(discount.buyer.id, val)"
              :ripple="false"
            />
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";
import rewardsApi from "@/services/rewards-api";
import customerDataBuyerPreferencesApi from "@/services/customer-data-buyer-preferences-api";
//import mixpanel from '@/services/mixpanel';

export default {
  name: "BuyersList",
  props: {
    eventType: {
      type: String,
      required: true,
    },
    extraData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      discounts: [],
      loading: true,
      saving: false,
      acceptAll: true,
      dataBuyers: {},
    };
  },
  computed: {
    ...mapState(["customer"]),
  },
  async created() {
    try {
      this.$emit("update:loading", true);
      await this.loadData();
    } catch (e) {
    } finally {
      this.$emit("update:loading", false);
    }

    //mixpanel.track('select_data_buyers_page');
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        const discounts = await this.getDiscounts();
        this.discounts = discounts;
        const preferences = await customerDataBuyerPreferencesApi.getPreferences();
        this.acceptAll = preferences.enableAllDataBuyers;
        this.dataBuyers = preferences.dataBuyers;
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        this.loading = false;
      }
    },
    async getDiscounts() {
      switch (this.eventType) {
        case "SURVEY_CHECKIN":
          return await rewardsApi.getSurveySubmitRewards(
            this.extraData.surveyTemplateId
          );
        case "CONNECTED_INSURANCE_POLICY_CHECKIN":
          return await rewardsApi.getConnectedInsurancePolicyCheckinRewards(
            "VERT_A2QFWOSJHGH4"
          );
        case "PROPERTY_CHECKIN":
          return await rewardsApi.getPropertyCheckinRewards(
            "",
            "",
            "VERT_A2QFWOSJHGH7"
          );
        case "VEHICLE_ACTIVATION":
          return await rewardsApi.getVehicleActivationRewards();
        case "VEHICLE_CHECKIN":
          return await rewardsApi.getVehicleCheckinRewards(
            this.extraData.vehicleId,
            this.extraData.reading
          );
        // Add other cases based on eventType
      }
    },
    getRewardAmount(discount) {
      let amount = 0;
      if (discount?.discounts?.length) {
        amount = discount.discounts.reduce(
          (sum, reward) => sum + reward.amount,
          0
        );
      }
      return amount.toFixed(2);
    },
    getBuyerStatus(buyerId) {
      const buyer = Object.values(this.dataBuyers).find(
        (b) => b.dataBuyerId === buyerId
      );
      return buyer ? buyer.enabled : true;
    },
    async saveAcceptAll(enabled) {
      this.saving = true;
      try {
        await customerDataBuyerPreferencesApi.saveAllEnabled(enabled);
        //mixpanel.track('maximize_earnings', { enabled });
      } finally {
        this.saving = false;
      }
    },
    async saveBuyerPreference(buyerId, enabled) {
      this.saving = true;
      try {
        await customerDataBuyerPreferencesApi.saveBuyerPreference(
          buyerId,
          enabled
        );
        this.$set(this.dataBuyers, buyerId, enabled);
        // mixpanel.track('select_data_buyer', {
        //   enabled,
        //   name: this.discounts.find(d => d.dataBuyer.id === buyerId)?.dataBuyer?.name
        // });
      } finally {
        this.saving = false;
      }
    },
    openBuyerDetails(buyerId) {
      this.$emit("open-buyer-details", buyerId);
    },
  },
};
</script>

<style scoped>
.buyer-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
}
</style>
