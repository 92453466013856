<template>
  <v-card>
    <v-card-title> Homes </v-card-title>
    <v-img
      height="160px"
      :src="imagePath"
      :contain="!checkIn"
      :cover="checkIn"
    ></v-img>
    <v-card-text class="pb-0 text-body-1">
      {{ home?.address?.address }}
      <v-btn
        v-if="!checkIn"
        @click="showMobileDialog = true"
        class="text-capitalize"
        color="primary"
        text
        small
        >Edit</v-btn
      >
      <template v-if="checkIn">
        <div>
          <span class="font-weight-medium mr-2">Checked in atr:</span>
          <span>{{
            checkIn?.checkedInAt | moment("YYYY-MM-DD HH:mm:ss")
          }}</span>
        </div>
        <div>
          <span class="font-weight-medium mr-2">Status:</span>
          <span>{{ status }}</span>
        </div>
      </template>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn class="text-capitalize" color="primary" @click="onContinue()">{{
        checkIn ? "View" : "Continue"
      }}</v-btn>
    </v-card-actions>
    <v-card-text class="d-flex align-center justify-center gap-4 pt-0">
      <p class="opacity mr-1">Have a question?</p>
      <a
        class="text-decoration-none"
        href="https://support.ownli.co/hc/en-us/requests/new"
        target="_blank"
      >
        Contact Us.</a
      >
    </v-card-text>
    <v-snackbar app v-model="snackbar">
      Check In feature is available exclusively in our app. Download the app to
      access it.
    </v-snackbar>
    <!-- Add the dialog component -->
    <MobileDialog v-model="showMobileDialog" />
  </v-card>
</template>

<script>
import MobileDialog from "@/components/global/MobileDialog.vue";

export default {
  name: "DisplayCard",
  components: {
    MobileDialog,
  },
  props: {
    home: {
      type: Object,
      required: true,
    },
    checkIn: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      snackbar: false,
      showMobileDialog: false,
    };
  },
  computed: {
    imagePath() {
      let path = "assets/buyers/svg/datapoint_home.svg";
      for (const step of this.checkIn?.steps || []) {
        if (step.type === "ProofOfAddress") {
          path = step.photos?.[0]?.photoUrl;
          break;
        }
      }
      return path;
    },
    status() {
      if (this.checkIn?.status?.toLowerCase() === "pending") {
        return "In Review";
      } else if (this.checkIn?.status?.toLowerCase() === "onhold") {
        return "On Hold";
      }
      return this.checkIn?.status;
    },
  },
  methods: {
    onContinue() {
      if (!this.checkIn) {
        this.showMobileDialog = true;
        return;
      }
      this.$router.push({
        path: `/my-homes/${this.home.id}/preview`,
        query: {
          checkIn: this.checkIn?.id,
        },
      });
    },
  },
};
</script>
