<template>
  <v-card class="py-2">
    <SetUpDone
      successText="Check-in complete!"
      imageUrl="/assets/images/survey.png"
      title2="See your data buyers"
      subTitle="It's Important for these companies to transparently purchase YOUR data from YOU."
    />
    <v-row v-if="loading" justify="center" align="center">
      <v-col align="center" cols="12" sm="8" md="6">
        <v-progress-circular
          align="center"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-card class="ma-3" v-for="(reward, index) in rewards" :key="'t' + index">
      <v-list-item @click="openBuyerDetails(reward?.buyerId)">
        <v-list-item-avatar size="50" class="rounded-0">
          <v-img :src="reward?.logo"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6">{{
            reward?.name
          }}</v-list-item-title>
          <v-list-item-subtitle class="text-wrap text-body-1">
            {{ reward?.buyerDescription }}
          </v-list-item-subtitle>
          <h4 class="mt-1 success--text">
            {{ getRewardAmount(reward) }}
          </h4>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-col align="center">
      <v-btn color="primary" class="d-block my-4 w-200 custom-btn" @click="navigate('/rewards')"
        >Go to my wallet</v-btn
      >
      <v-btn
        color="primary"
        class="d-block my-4 w-200 custom-btn"
        @click="navigate('/home-card')"
        >Go to my data</v-btn
      >
      <v-btn @click="socialShareModal = true">
        <v-icon class="mr-2">$vuetify.icons.actions_share_fill</v-icon>
        share with friends
      </v-btn>
    </v-col>
    <v-dialog v-model="showBuyerDetails" fullscreen hide-overlay>
      <buyer-details
        v-if="selectedBuyerId"
        :buyer-id="selectedBuyerId"
        @dismiss="closeBuyerDetails"
      />
    </v-dialog>
    <v-dialog v-model="socialShareModal" max-width="400">
      <v-card class="pa-4 pb-8" align="center">
        <h1 class="text-h5 mb-4">Share on</h1>
        <v-row no-gutters>
          <v-col>
            <ShareNetwork
              network="Email"
              :url="url"
              :title="title"
              :description="description"
            >
              <v-btn>
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </ShareNetwork>
          </v-col>
          <v-col>
            <ShareNetwork
              network="Facebook"
              :url="url"
              :title="title"
              :description="description"
            >
              <v-btn>
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
            </ShareNetwork>
          </v-col>
          <v-col>
            <ShareNetwork
              network="WhatsApp"
              :url="url"
              :title="title"
              :description="description"
            >
              <v-btn>
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </ShareNetwork>
          </v-col>
          <v-col>
            <ShareNetwork
              network="Skype"
              :url="url"
              :title="title"
              :description="description"
            >
              <v-btn>
                <v-icon>mdi-skype</v-icon>
              </v-btn>
            </ShareNetwork>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import rewardsApi from "@/services/rewards-api";
import SetUpDone from "@/components/global/SetUpDone.vue";
import api from "@/services/api-service";
import BuyerDetails from "@/components/customer/BuyerDetails.vue";

export default {
  name: "SurveyDone",
  components: {
    SetUpDone,
    BuyerDetails,
  },
  props: {
    surveyId: {
      type: String,
      required: true,
    },
    surveyTemplate: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      showBuyerDetails: false,
      selectedBuyerId: null,
      rewards: [],
      downstreamActionAllowed: true,
      socialShareModal: false,
      url: "https://www.ownli.co",
      title: "Know your worth",
      description:
        "Hey, I just earned money with Ownli. You can too...it’s easy. Join us on https://www.ownli.co",
    };
  },
  computed: {
    showCustomCelebration() {
      return (
        this.surveyTemplate?.celebrationPageText &&
        this.surveyTemplate?.celebrationActions?.length > 0 &&
        this.downstreamActionAllowed
      );
    },
  },
  methods: {
    async fetchRewards() {
      this.loading = true;
      try {
        //this.surveyId = "CSUR_yXKBbErd8KfZ";
        const data = await rewardsApi.getCheckinRewardsFor(this.surveyId);
        this.rewards = data;
      } catch (error) {
        console.error("Error fetching rewards:", error);
      } finally {
        this.loading = false;
      }
    },
    getRewardAmount(reward) {
      let amount = reward?.amount;
      amount = amount || 0;
      return "$" + amount.toFixed(2);
    },
    navigate(url) {
      this.$router.replace(url);
    },
    async handleCelebrationAction(action) {
      if (action.url) {
        if (action.url === "ownli://insurance_policy") {
          this.navigate("/policies");
          return;
        }

        let url = action.url;
        if (url.includes("%UNIQUE_ID%")) {
          url = url.replace("%UNIQUE_ID%", this.surveyId);
        }

        window.open(url, "_blank");
      }
    },
    async checkIfDownstreamActionAllowed() {
      if (this.surveyTemplate?.id === "STEM_INSURANCE_POLICY_PREFILL_SURVEY") {
        this.loading = true;
        try {
          const response = await api.get("/policies/v1/checkin-allowed");
          this.downstreamActionAllowed = response.data.allowed;
        } catch (error) {
          console.error("Error checking downstream action:", error);
        } finally {
          this.loading = false;
        }
      }
    },
    openBuyerDetails(buyerId) {
      this.selectedBuyerId = buyerId;
      this.showBuyerDetails = true;
    },
    closeBuyerDetails() {
      this.showBuyerDetails = false;
      this.selectedBuyerId = null;
    },
  },
  
  mounted() {
  },
  async created() {
    await this.checkIfDownstreamActionAllowed();
    await this.fetchRewards();
  },

};
</script>

<style scoped>
.check-in-img {
  max-width: 150px;
}
.darken-2 {
  color: #616161;
}
.lh-18 {
  line-height: 24;
}
.custom-btn {
width: 200px; 
}
</style>
