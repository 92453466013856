<template>
  <div class="text-center my-3">
    <v-btn
      text
      class="text-uppercase text-decoration-underline text-body-2"
      :class="buttonColorClass"
      @click="openModal"
    >
      Choose which companies can buy your info
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'BuyerPreferencesLink',
  props: {
    eventType: {
      type: String,
      required: true
    },
    extraData: {
      type: Object,
      default: () => ({})
    },
    buttonColorClass: {
      type: String,
      default: 'primary--text'
    }
  },
  methods: {
    openModal() {
      this.$emit('open-buyers-modal', {
        eventType: this.eventType,
        extraData: this.extraData
      });
    }
  }
};
</script>